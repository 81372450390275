import { createContext, useContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signInWithCredential,
} from 'firebase/auth';
import firebaseUtils from './Firebase';
import { dashboardAPI } from './DashboardAPI';

import { Login } from './Login';

export const AuthContext = createContext({ authLoading: true, user: false });

export default function AuthProvider(props) {
  const provider = new GoogleAuthProvider();

  const login = async () => {
    setAuthLoading(true);
    Login(provider, firebaseUtils('auth'), setAuthLoading);
  };
  const logout = () => {
    setUser(false);
    firebaseUtils('auth')
      .signOut()
      .catch((x) => {
        console.log('probb signing out', x);
      });
  };
  const refreshMarketApps = (user2) => {
    setMarketAppsLoading(true);

    dashboardAPI.getMarketApps(user2).then((x) => {
      if (x.data.apps !== null) {
        setMarketApps(x.data.apps);
      }

      if (x.data.robinhood_email !== null && x.data.robinhood_email !== '') {
        setRhEmail(x.data.robinhood_email);
        if (
          x.data.session_expires_on !== null &&
          new Date(x.data.session_expires_on).getTime() > 0
        ) {
          setRhSessionExpirationDate(new Date(x.data.session_expires_on));
        }

        if (x.data.rh_crypto_set_up !== null) {
          setRhCryptoSetUp(x.data.rh_crypto_set_up);
        }
        setPatterndayTradingOverride(x.data.pattern_day_trading_override);
      }
      setConfiguredCryptoSettings(x.data.crypto_settings_exists);
      setCryptoInvestSettings(x.data.crypto_invest_settings);
      setAcceptedTerms(x.data.accepted_terms);
      setPlaySettings(x.data.bet_setting);

      setMarketAppsLoading(false);
    });
  };

  const [user, setUser] = useState(false);
  const [patternDayTradingOverride, setPatterndayTradingOverride] =
    useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [marketAppsLoading, setMarketAppsLoading] = useState(true);
  const [marketApps, setMarketApps] = useState([]);
  const [rhSessionExpirationDate, setRhSessionExpirationDate] = useState(null);
  const [ignoreRhSessionExpirationDate, setIgnoreRhSessionExpirationDate] =
    useState(false);
  const [rhEmail, setRhEmail] = useState(null);
  const [rhCryptoSetUp, setRhCryptoSetUp] = useState(false);
  const [configuredCryptoSettings, setConfiguredCryptoSettings] =
    useState(false);
  const [exchangePrices, setExchangePrices] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [cryptoInvestSettings, setCryptoInvestSettings] = useState(null);
  const [playSettings, setPlaySettings] = useState('BULL');
  useEffect(() => {
    console.log('calling authstate changed???');

    dashboardAPI.getExchangePrices().then((x) => {
      console.log('rsponse', x.data);
      setExchangePrices(x.data);
    });

    onAuthStateChanged(
      firebaseUtils('auth'),
      (loggedInUser) => {
        if (loggedInUser === null) {
          console.log('user is null wtfff');
          setAuthLoading(false);
          setMarketAppsLoading(false);
          return;
        }
        if (loggedInUser) {
          setMarketAppsLoading(true);
          (async () => {
            setUser(loggedInUser);
            dashboardAPI.getMarketApps(loggedInUser).then((x) => {
              console.log('response from market apps', x.data);
              if (x.data.apps !== null) {
                setMarketApps(x.data.apps);
              }

              if (
                x.data.robinhood_email !== null &&
                x.data.robinhood_email !== ''
              ) {
                setRhEmail(x.data.robinhood_email);
                if (
                  x.data.session_expires_on !== null &&
                  new Date(x.data.session_expires_on).getTime() > 0
                ) {
                  setRhSessionExpirationDate(
                    new Date(x.data.session_expires_on),
                  );
                }
                if (x.data.rh_crypto_set_up !== null) {
                  setRhCryptoSetUp(x.data.rh_crypto_set_up);
                }
                setPatterndayTradingOverride(
                  x.data.pattern_day_trading_override,
                );
              } else {
                setRhCryptoSetUp(true);
                setRhSessionExpirationDate(new Date(x.data.session_expires_on));
              }

              setConfiguredCryptoSettings(x.data.crypto_settings_exists);
              setCryptoInvestSettings(x.data.crypto_invest_settings);
              setAcceptedTerms(x.data.accepted_terms);
              setPlaySettings(x.data.bet_setting);
              setMarketAppsLoading(false);
              setAuthLoading(false);
            });
          })();
        } else {
          setAuthLoading(false);
        }
      },
      (err) => {
        console.log('errror from calling auth state' + err);
        setUser(false);
        setAuthLoading(false);
        setMarketAppsLoading(false);
      },
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authLoading,
        user,
        login,
        logout,
        marketApps,
        marketAppsLoading,
        rhSessionExpirationDate,
        rhEmail,
        refreshMarketApps,
        exchangePrices,
        configuredCryptoSettings,
        cryptoInvestSettings,
        rhCryptoSetUp,
        acceptedTerms,
        ignoreRhSessionExpirationDate,
        setIgnoreRhSessionExpirationDate,
        setUser,
        playSettings,
        patternDayTradingOverride,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
