import { StatusBar, StyleSheet } from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { GluestackUIProvider, Button, ButtonText } from '@gluestack-ui/themed';

//import { config } from '@gluestack-ui/config';
import { config } from './config/gluestack-ui.config';

import React from 'react';

import PlayDetails from './ui_components/PlayDetails';
import Home from './ui_components/Home';
import AuthProvider, { AuthContext } from './AuthProvider';
import AddFunds from './ui_components/AddFunds';
import EditManyPlays from './ui_components/EditManyPlays';
import ManageFunds from './ui_components/ManageFunds';

import 'react-native-gesture-handler';
import CreatePlay from './ui_components/CreatePlay';
import RobinhoodLogin from './ui_components/RobinhoodLogin';
import BitsoLogin from './ui_components/BitsoLogin';
import CryptoDetails from './ui_components/CryptoDetails';
import CryptoPilotSignUp from './ui_components/CryptoPilotSignUp';
import DeleteUser from './ui_components/DeleteUser';
import Privacy from './ui_components/Privacy';
import Terms from './ui_components/Terms';
import Support from './ui_components/Support';
import About from './ui_components/About';
import RobinhoodApiLogin from './ui_components/RobinhoodApiLogin';
import BinanceLogin from './ui_components/BinanceLogin';
import RobinhoodGuide from './ui_components/RobinhoodGuide';
import BitsoGuide from './ui_components/BitsoGuide';
import BinanceGuide from './ui_components/BinanceGuide';
import InvestmentSelectionScreen from './ui_components/InvestmentSelectionScreen';

const Stack = createStackNavigator();

export default function App() {
  // Set an initializing state whilst Firebase connects
  // const [initializing, setInitializing] = useState(true);

  {
    /*https://github.com/oblador/react-native-vector-icons#examples*/
  }

  const linking = {
    config: {
      screens: {
        Home: {
          screens: {
            Plays: 'Plays',
            CryptoPilot: '',
            Settings: 'Settings',
          },
        },
        PlayDetails: 'play/:play',
        DeleteUser: 'deleteUser',
        Privacy: 'privacy',
        Support: 'support',
        Terms: 'terms',
        About: 'about',
        BitsoGuide: 'bitso_guide',
        RobinhoodGuide: 'robinhood_guide',
        BinanceGuide: 'binance_guide',
        RobinhoodLogin: 'robinhood_login',
        RobinhoodApiLogin: 'robinhood_api_login',
        BinanceLogin: 'binance_login',
        BitsoLogin: 'bitso_login',
      },
    },
  };

  const HeaderTitle = (navigation) => {
    return (
      <Button
        size={'lg'}
        variant={'link'}
        onPress={() => navigation.navigation.navigate('Home')}
        action={'positive'}
      >
        <ButtonText> CryptoPilot.app </ButtonText>
      </Button>
    );
  };

  return (
    <GluestackUIProvider config={config}>
      <StatusBar barStyle={'dark-content'} />
      <AuthProvider>
        <NavigationContainer linking={linking}>
          <Stack.Navigator>
            <Stack.Screen
              options={{ headerShown: false }}
              name="Home"
              component={Home}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: false,
              })}
              name="PlayDetails"
              component={PlayDetails}
            />

            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="RobinhoodLogin"
              component={RobinhoodLogin}
            />

            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="CryptoPilotSignUp"
              component={CryptoPilotSignUp}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="DeleteUser"
              component={DeleteUser}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="RobinhoodGuide"
              component={RobinhoodGuide}
            />

            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="BinanceGuide"
              component={BinanceGuide}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="BitsoGuide"
              component={BitsoGuide}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="Support"
              component={Support}
            />

            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="About"
              component={About}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="Privacy"
              component={Privacy}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="Terms"
              component={Terms}
            />

            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="BitsoLogin"
              component={BitsoLogin}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="BinanceLogin"
              component={BinanceLogin}
            />

            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="RobinhoodApiLogin"
              component={RobinhoodApiLogin}
            />

            <Stack.Screen
              options={({ navigation, route }) => ({
                headerShown: false,
              })}
              name="CreatePlay"
              component={CreatePlay}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="EditManyPlays"
              component={EditManyPlays}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="AddFunds"
              component={AddFunds}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="InvestmentSelectionScreen"
              component={InvestmentSelectionScreen}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => <HeaderTitle navigation={navigation} />,
                headerLargeTitle: true,
              })}
              name="ManageFunds"
              component={ManageFunds}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerShown: false,
              })}
              name="CryptoDetails"
              component={CryptoDetails}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </AuthProvider>
    </GluestackUIProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    backgroundColor: '#4630eb',
    borderRadius: 4,
    padding: 12,
    marginVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 16,
  },
});
