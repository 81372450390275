import axios from 'axios';

import { BASE_URL, ENCRYPT_KEY, ENCRYPT_KEY_IV } from '@env';
import CryptoJS from 'crypto-js';

const CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION = {
  BTC: 2,
  ETH: 2,
  DOGE: 6,
  AVAX: 2,
  BCH: 2,
  UNI: 3,
  LINK: 2,
  XLM: 6,
  ETC: 2,
  LTC: 2,
  COMP: 2,
  AAVE: 2,
  XTZ: 3,
  XRP: 3,
  POL: 3,
  SOL: 3,
  ADA: 3,
  DOT: 3,
  SHIB: 8,
  VFS: 2,
  SMCI: 2,
  IAC: 2,
  NOW: 2,
  AAL: 2,
  HD: 2,
  WDAY: 2,
  CHWY: 2,
  META: 2,
  NFLX: 2,
  DAL: 2,
  NIO: 2,
  CRM: 2,
  AAPL: 2,
  MSFT: 2,
  HOOD: 2,
  RBLX: 2,
  RIVN: 2,
  KOSS: 2,
  GME: 2,
  XOM: 2,
  HLX: 2,
  TSM: 2,
  NVDA: 2,
  AI: 2,
  SAP: 2,
  AMD: 2,
  QCOM: 2,
  PANW: 2,
  EQIX: 2,
  GOOG: 2,
  COP: 2,
  UUUU: 2,
  NET: 2,
  MANH: 2,
  FROG: 2,
  PD: 2,
  FTHM: 2,
  WMT: 2,
  LLY: 2,
  CRSP: 2,
  AUST: 2,
  GXO: 2,
  AIT: 2,
  MU: 2,
  SNOW: 2,
  TEAM: 2,
  DOCN: 2,
  ZS: 2,
  OKTA: 2,
  SQ: 2,
  TALO: 2,
  INFY: 2,
  CDNS: 2,
  SNPS: 2,
  AMX: 2,
  AMAT: 2,
  CEG: 2,
  PLUG: 2,
  LIN: 2,
  BLDP: 2,
  IREN: 2,
  ADBE: 2,
  CLSK: 2,
  MARA: 2,
  RIOT: 2,
  MSTR: 2,
  CLCO: 2,
  JKS: 2,
  SPY: 2,
  VOO: 2,
  IVV: 2,
  VTI: 2,
  QQQ: 2,
  VUG: 2,
  VEA: 2,
  VTV: 2,
  IEFA: 2,
  AGG: 2,
  BND: 2,
  IWF: 2,
  IJH: 2,
  VIG: 2,
  IJR: 2,
  VWO: 2,
  IEMG: 2,
  GLD: 2,
  VGT: 2,
  VXUS: 2,
  VO: 2,
  XLK: 2,
  IWM: 2,
  RSP: 2,
  SCHD: 2,
  NEP: 2,
  SMXT: 2,
  UMAC: 2,
  MOBX: 2,
  OPTT: 2,
  FLUX: 2,
  WAVE: 2,
  NNE: 2,
  UEC: 2,
  CCJ: 2,
  DDD: 2,
  PATH: 2,
  SYM: 2,
  PYPL: 2,
  GTLB: 2,
  PAC: 2,
  GAMB: 2,
  BBCP: 2,
  LAAC: 2,
  LAC: 2,
  APD: 2,
  HYLN: 2,
  HTOO: 2,
  HAL: 2,
  AAON: 2,
  AYI: 2,
  ADSE: 2,
  ADN: 2,
  AMPS: 2,
  AMRC: 2,
  APOG: 2,
  AQMS: 2,
  ACHR: 2,
  ARRY: 2,
  AY: 2,
  RERE: 2,
  BECN: 2,
  BYND: 2,
  BLNK: 2,
  BWEN: 2,
  BEPC: 2,
  CSIQ: 2,
  GOEV: 2,
  CARR: 2,
  CBAT: 2,
  CENN: 2,
  LEU: 2,
  CHPT: 2,
  CWEN: 2,
  FIX: 2,
  CMC: 2,
  CRKN: 2,
  CSWI: 2,
  DNMR: 2,
  DQ: 2,
  DNN: 2,
  ETN: 2,
  NRGV: 2,
  ENPH: 2,
  EOSE: 2,
  GWH: 2,
  EVGO: 2,
  EZGO: 2,
  FSLR: 2,
  FLNC: 2,
  FRGT: 2,
  FREY: 2,
  FTCI: 2,
  FF: 2,
  GATO: 2,
  GFL: 2,
  HDSN: 2,
  HYZN: 2,
  RBA: 2,
  IBP: 2,
  IONR: 2,
  IPX: 2,
  ITRI: 2,
  JZXN: 2,
  JOBY: 2,
  JCI: 2,
  KNDI: 2,
  KULR: 2,
  LSF: 2,
  LII: 2,
  LI: 2,
  LICY: 2,
  LTBR: 2,
  LKQ: 2,
  LOOP: 2,
  LYTS: 2,
  MAXN: 2,
  MVST: 2,
  MNTK: 2,
  MP: 2,
  MULN: 2,
  NXE: 2,
  NKLA: 2,
  NIU: 2,
  NUE: 2,
  SMR: 2,
  OTLY: 2,
  NVVE: 2,
  ORGN: 2,
  OESX: 2,
  ORA: 2,
  PESI: 2,
  PLPC: 2,
  PCT: 2,
  NX: 2,
  PWR: 2,
  QRHC: 2,
  RNW: 2,
  REFR: 2,
  RETO: 2,
  SPTN: 2,
  LEDS: 2,
  SHLS: 2,
  SEDG: 2,
  SLDP: 2,
  SCCO: 2,
  SPRU: 2,
  STKH: 2,
  STLD: 2,
  NOVA: 2,
  STKL: 2,
  RUN: 2,
  SWVL: 2,
  TGLS: 2,
  HAIN: 2,
  TMC: 2,
  BLD: 2,
  TPIC: 2,
  URG: 2,
  VMI: 2,
  WCN: 2,
  WM: 2,
  WTS: 2,
  WWR: 2,
  WLDN: 2,
  XOS: 2,
  XPEV: 2,
};

const CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION = {
  BTC: 8,
  ETH: 8,
  DOGE: 2,
  AVAX: 4,
  BCH: 8,
  UNI: 3,
  LINK: 3,
  XLM: 0,
  ETC: 6,
  LTC: 6,
  COMP: 4,
  AAVE: 4,
  XTZ: 3,
  XRP: 6,
  POL: 6,
  SOL: 6,
  ADA: 6,
  DOT: 6,
  SHIB: 0,

  VFS: 6,
  SMCI: 6,
  IAC: 6,
  NOW: 6,
  AAL: 6,
  HD: 6,
  WDAY: 6,
  CHWY: 6,
  META: 6,
  NFLX: 6,
  DAL: 6,
  NIO: 6,
  CRM: 6,
  AAPL: 6,
  MSFT: 6,
  HOOD: 6,
  RBLX: 6,
  RIVN: 6,
  KOSS: 6,
  GME: 6,
  XOM: 6,
  HLX: 6,
  TSM: 6,
  NVDA: 6,
  AI: 6,
  SAP: 6,
  AMD: 6,
  QCOM: 6,
  PANW: 6,
  EQIX: 6,
  GOOG: 6,
  COP: 6,
  UUUU: 6,
  NET: 6,
  MANH: 6,
  FROG: 6,
  PD: 6,
  FTHM: 6,
  WMT: 6,
  LLY: 6,
  CRSP: 6,
  AUST: 6,
  GXO: 6,
  AIT: 6,
  MU: 6,
  SNOW: 6,
  TEAM: 6,
  DOCN: 6,
  ZS: 6,
  OKTA: 6,
  SQ: 6,
  TALO: 6,
  INFY: 6,
  CDNS: 6,
  SNPS: 6,
  AMX: 6,
  AMAT: 6,
  CEG: 6,
  PLUG: 6,
  LIN: 6,
  BLDP: 6,
  IREN: 6,
  ADBE: 6,
  CLSK: 6,
  MARA: 6,
  RIOT: 6,
  MSTR: 6,
  CLCO: 6,
  JKS: 6,
  SPY: 6,
  VOO: 6,
  IVV: 6,
  VTI: 6,
  QQQ: 6,
  VUG: 6,
  VEA: 6,
  VTV: 6,
  IEFA: 6,
  AGG: 6,
  BND: 6,
  IWF: 6,
  IJH: 6,
  VIG: 6,
  IJR: 6,
  VWO: 6,
  IEMG: 6,
  GLD: 6,
  VGT: 6,
  VXUS: 6,
  VO: 6,
  XLK: 6,
  IWM: 6,
  RSP: 6,
  SCHD: 6,
  NEP: 6,
  SMXT: 6,
  UMAC: 6,
  MOBX: 6,
  OPTT: 6,
  FLUX: 6,
  WAVE: 6,
  NNE: 6,
  UEC: 6,
  CCJ: 6,
  DDD: 6,
  PATH: 6,
  SYM: 6,
  PYPL: 6,
  GTLB: 6,
  PAC: 6,
  GAMB: 6,
  BBCP: 6,
  LAAC: 6,
  LAC: 6,
  APD: 6,
  HYLN: 6,
  HTOO: 6,
  HAL: 6,
  AAON: 6,
  AYI: 6,
  ADSE: 6,
  ADN: 6,
  AMPS: 6,
  AMRC: 6,
  APOG: 6,
  AQMS: 6,
  ACHR: 6,
  ARRY: 6,
  AY: 6,
  RERE: 6,
  BECN: 6,
  BYND: 6,
  BLNK: 6,
  BWEN: 6,
  BEPC: 6,
  CSIQ: 6,
  GOEV: 6,
  CARR: 6,
  CBAT: 6,
  CENN: 6,
  LEU: 6,
  CHPT: 6,
  CWEN: 6,
  FIX: 6,
  CMC: 6,
  CRKN: 6,
  CSWI: 6,
  DNMR: 6,
  DQ: 6,
  DNN: 6,
  ETN: 6,
  NRGV: 6,
  ENPH: 6,
  EOSE: 6,
  GWH: 6,
  EVGO: 6,
  EZGO: 6,
  FSLR: 6,
  FLNC: 6,
  FRGT: 6,
  FREY: 6,
  FTCI: 6,
  FF: 6,
  GATO: 6,
  GFL: 6,
  HDSN: 6,
  HYZN: 6,
  RBA: 6,
  IBP: 6,
  IONR: 6,
  IPX: 6,
  ITRI: 6,
  JZXN: 6,
  JOBY: 6,
  JCI: 6,
  KNDI: 6,
  KULR: 6,
  LSF: 6,
  LII: 6,
  LI: 6,
  LICY: 6,
  LTBR: 6,
  LKQ: 6,
  LOOP: 6,
  LYTS: 6,
  MAXN: 6,
  MVST: 6,
  MNTK: 6,
  MP: 6,
  MULN: 6,
  NXE: 6,
  NKLA: 6,
  NIU: 6,
  NUE: 6,
  SMR: 6,
  OTLY: 6,
  NVVE: 6,
  ORGN: 6,
  OESX: 6,
  ORA: 6,
  PESI: 6,
  PLPC: 6,
  PCT: 6,
  NX: 6,
  PWR: 6,
  QRHC: 6,
  RNW: 6,
  REFR: 6,
  RETO: 6,
  SPTN: 6,
  LEDS: 6,
  SHLS: 6,
  SEDG: 6,
  SLDP: 6,
  SCCO: 6,
  SPRU: 6,
  STKH: 6,
  STLD: 6,
  NOVA: 6,
  STKL: 6,
  RUN: 6,
  SWVL: 6,
  TGLS: 6,
  HAIN: 6,
  TMC: 6,
  BLD: 6,
  TPIC: 6,
  URG: 6,
  VMI: 6,
  WCN: 6,
  WM: 6,
  WTS: 6,
  WWR: 6,
  WLDN: 6,
  XOS: 6,
  XPEV: 6,
};

const cryptoIconMap = {
  ETH: require('./assets/eth.png'),
  DOGE: require('./assets/doge.png'),
  BTC: require('./assets/btc.png'),
  AVAX: require('./assets/avax.png'),
  XLM: require('./assets/xlm.png'),
  BCH: require('./assets/bch.png'),
  LINK: require('./assets/link.png'),
  UNI: require('./assets/uni.png'),
  ETC: require('./assets/etc.png'),
  XTZ: require('./assets/xtz.png'),
  COMP: require('./assets/comp.png'),
  AAVE: require('./assets/aave.png'),
  LTC: require('./assets/ltc.png'),
  default: require('./assets/grin.png'),
  XRP: require('./assets/xrp.png'),
  POL: require('./assets/matic.png'),
  SOL: require('./assets/sol.png'),
  ADA: require('./assets/ada.png'),
  DOT: require('./assets/dot.png'),
  SHIB: require('./assets/shib.png'),
  VFS: require('./assets/factory-sm.png'),
  SMCI: require('./assets/factory-sm.png'),
  IAC: require('./assets/factory-sm.png'),
  NOW: require('./assets/factory-sm.png'),
  AAL: require('./assets/factory-sm.png'),
  HD: require('./assets/factory-sm.png'),
  WDAY: require('./assets/factory-sm.png'),
  CHWY: require('./assets/factory-sm.png'),
  META: require('./assets/factory-sm.png'),
  NFLX: require('./assets/factory-sm.png'),
  DAL: require('./assets/factory-sm.png'),
  NIO: require('./assets/factory-sm.png'),
  CRM: require('./assets/factory-sm.png'),
  AAPL: require('./assets/factory-sm.png'),
  MSFT: require('./assets/factory-sm.png'),
  HOOD: require('./assets/factory-sm.png'),
  RBLX: require('./assets/factory-sm.png'),
  RIVN: require('./assets/factory-sm.png'),
  KOSS: require('./assets/factory-sm.png'),
  GME: require('./assets/factory-sm.png'),
  XOM: require('./assets/factory-sm.png'),
  HLX: require('./assets/factory-sm.png'),
  TSM: require('./assets/factory-sm.png'),
  NVDA: require('./assets/factory-sm.png'),
  AI: require('./assets/factory-sm.png'),
  SAP: require('./assets/factory-sm.png'),
  AMD: require('./assets/factory-sm.png'),
  QCOM: require('./assets/factory-sm.png'),
  PANW: require('./assets/factory-sm.png'),
  EQIX: require('./assets/factory-sm.png'),
  GOOG: require('./assets/factory-sm.png'),
  COP: require('./assets/factory-sm.png'),
  UUUU: require('./assets/factory-sm.png'),
  NET: require('./assets/factory-sm.png'),
  MANH: require('./assets/factory-sm.png'),
  FROG: require('./assets/factory-sm.png'),
  PD: require('./assets/factory-sm.png'),
  FTHM: require('./assets/factory-sm.png'),
  WMT: require('./assets/factory-sm.png'),
  LLY: require('./assets/factory-sm.png'),
  CRSP: require('./assets/factory-sm.png'),
  AUST: require('./assets/factory-sm.png'),
  GXO: require('./assets/factory-sm.png'),
  AIT: require('./assets/factory-sm.png'),
  MU: require('./assets/factory-sm.png'),
  SNOW: require('./assets/factory-sm.png'),
  TEAM: require('./assets/factory-sm.png'),
  DOCN: require('./assets/factory-sm.png'),
  ZS: require('./assets/factory-sm.png'),
  OKTA: require('./assets/factory-sm.png'),
  SQ: require('./assets/factory-sm.png'),
  TALO: require('./assets/factory-sm.png'),
  INFY: require('./assets/factory-sm.png'),
  CDNS: require('./assets/factory-sm.png'),
  SNPS: require('./assets/factory-sm.png'),
  AMX: require('./assets/factory-sm.png'),
  AMAT: require('./assets/factory-sm.png'),
  CEG: require('./assets/factory-sm.png'),
  PLUG: require('./assets/factory-sm.png'),
  LIN: require('./assets/factory-sm.png'),
  BLDP: require('./assets/factory-sm.png'),
  IREN: require('./assets/factory-sm.png'),
  ADBE: require('./assets/factory-sm.png'),
  CLSK: require('./assets/factory-sm.png'),
  MARA: require('./assets/factory-sm.png'),
  RIOT: require('./assets/factory-sm.png'),
  MSTR: require('./assets/factory-sm.png'),
  CLCO: require('./assets/factory-sm.png'),
  JKS: require('./assets/factory-sm.png'),
  SPY: require('./assets/factory-sm.png'),
  VOO: require('./assets/factory-sm.png'),
  IVV: require('./assets/factory-sm.png'),
  VTI: require('./assets/factory-sm.png'),
  QQQ: require('./assets/factory-sm.png'),
  VUG: require('./assets/factory-sm.png'),
  VEA: require('./assets/factory-sm.png'),
  VTV: require('./assets/factory-sm.png'),
  IEFA: require('./assets/factory-sm.png'),
  AGG: require('./assets/factory-sm.png'),
  BND: require('./assets/factory-sm.png'),
  IWF: require('./assets/factory-sm.png'),
  IJH: require('./assets/factory-sm.png'),
  VIG: require('./assets/factory-sm.png'),
  IJR: require('./assets/factory-sm.png'),
  VWO: require('./assets/factory-sm.png'),
  IEMG: require('./assets/factory-sm.png'),
  GLD: require('./assets/factory-sm.png'),
  VGT: require('./assets/factory-sm.png'),
  VXUS: require('./assets/factory-sm.png'),
  VO: require('./assets/factory-sm.png'),
  XLK: require('./assets/factory-sm.png'),
  IWM: require('./assets/factory-sm.png'),
  RSP: require('./assets/factory-sm.png'),
  SCHD: require('./assets/factory-sm.png'),
  NEP: require('./assets/factory-sm.png'),
  SMXT: require('./assets/factory-sm.png'),
  UMAC: require('./assets/factory-sm.png'),
  MOBX: require('./assets/factory-sm.png'),
  OPTT: require('./assets/factory-sm.png'),
  FLUX: require('./assets/factory-sm.png'),
  WAVE: require('./assets/factory-sm.png'),
  NNE: require('./assets/factory-sm.png'),
  UEC: require('./assets/factory-sm.png'),
  CCJ: require('./assets/factory-sm.png'),
  DDD: require('./assets/factory-sm.png'),
  PATH: require('./assets/factory-sm.png'),
  SYM: require('./assets/factory-sm.png'),
  PYPL: require('./assets/factory-sm.png'),
  GTLB: require('./assets/factory-sm.png'),
  PAC: require('./assets/factory-sm.png'),
  GAMB: require('./assets/factory-sm.png'),
  BBCP: require('./assets/factory-sm.png'),
  LAAC: require('./assets/factory-sm.png'),
  LAC: require('./assets/factory-sm.png'),
  APD: require('./assets/factory-sm.png'),
  HYLN: require('./assets/factory-sm.png'),
  HTOO: require('./assets/factory-sm.png'),
  HAL: require('./assets/factory-sm.png'),
  AAON: require('./assets/factory-sm.png'),
  AYI: require('./assets/factory-sm.png'),
  ADSE: require('./assets/factory-sm.png'),
  ADN: require('./assets/factory-sm.png'),
  AMPS: require('./assets/factory-sm.png'),
  AMRC: require('./assets/factory-sm.png'),
  APOG: require('./assets/factory-sm.png'),
  AQMS: require('./assets/factory-sm.png'),
  ACHR: require('./assets/factory-sm.png'),
  ARRY: require('./assets/factory-sm.png'),
  AY: require('./assets/factory-sm.png'),
  RERE: require('./assets/factory-sm.png'),
  BECN: require('./assets/factory-sm.png'),
  BYND: require('./assets/factory-sm.png'),
  BLNK: require('./assets/factory-sm.png'),
  BWEN: require('./assets/factory-sm.png'),
  BEPC: require('./assets/factory-sm.png'),
  CSIQ: require('./assets/factory-sm.png'),
  GOEV: require('./assets/factory-sm.png'),
  CARR: require('./assets/factory-sm.png'),
  CBAT: require('./assets/factory-sm.png'),
  CENN: require('./assets/factory-sm.png'),
  LEU: require('./assets/factory-sm.png'),
  CHPT: require('./assets/factory-sm.png'),
  CWEN: require('./assets/factory-sm.png'),
  FIX: require('./assets/factory-sm.png'),
  CMC: require('./assets/factory-sm.png'),
  CRKN: require('./assets/factory-sm.png'),
  CSWI: require('./assets/factory-sm.png'),
  DNMR: require('./assets/factory-sm.png'),
  DQ: require('./assets/factory-sm.png'),
  DNN: require('./assets/factory-sm.png'),
  ETN: require('./assets/factory-sm.png'),
  NRGV: require('./assets/factory-sm.png'),
  ENPH: require('./assets/factory-sm.png'),
  EOSE: require('./assets/factory-sm.png'),
  GWH: require('./assets/factory-sm.png'),
  EVGO: require('./assets/factory-sm.png'),
  EZGO: require('./assets/factory-sm.png'),
  FSLR: require('./assets/factory-sm.png'),
  FLNC: require('./assets/factory-sm.png'),
  FRGT: require('./assets/factory-sm.png'),
  FREY: require('./assets/factory-sm.png'),
  FTCI: require('./assets/factory-sm.png'),
  FF: require('./assets/factory-sm.png'),
  GATO: require('./assets/factory-sm.png'),
  GFL: require('./assets/factory-sm.png'),
  HDSN: require('./assets/factory-sm.png'),
  HYZN: require('./assets/factory-sm.png'),
  RBA: require('./assets/factory-sm.png'),
  IBP: require('./assets/factory-sm.png'),
  IONR: require('./assets/factory-sm.png'),
  IPX: require('./assets/factory-sm.png'),
  ITRI: require('./assets/factory-sm.png'),
  JZXN: require('./assets/factory-sm.png'),
  JOBY: require('./assets/factory-sm.png'),
  JCI: require('./assets/factory-sm.png'),
  KNDI: require('./assets/factory-sm.png'),
  KULR: require('./assets/factory-sm.png'),
  LSF: require('./assets/factory-sm.png'),
  LII: require('./assets/factory-sm.png'),
  LI: require('./assets/factory-sm.png'),
  LICY: require('./assets/factory-sm.png'),
  LTBR: require('./assets/factory-sm.png'),
  LKQ: require('./assets/factory-sm.png'),
  LOOP: require('./assets/factory-sm.png'),
  LYTS: require('./assets/factory-sm.png'),
  MAXN: require('./assets/factory-sm.png'),
  MVST: require('./assets/factory-sm.png'),
  MNTK: require('./assets/factory-sm.png'),
  MP: require('./assets/factory-sm.png'),
  MULN: require('./assets/factory-sm.png'),
  NXE: require('./assets/factory-sm.png'),
  NKLA: require('./assets/factory-sm.png'),
  NIU: require('./assets/factory-sm.png'),
  NUE: require('./assets/factory-sm.png'),
  SMR: require('./assets/factory-sm.png'),
  OTLY: require('./assets/factory-sm.png'),
  NVVE: require('./assets/factory-sm.png'),
  ORGN: require('./assets/factory-sm.png'),
  OESX: require('./assets/factory-sm.png'),
  ORA: require('./assets/factory-sm.png'),
  PESI: require('./assets/factory-sm.png'),
  PLPC: require('./assets/factory-sm.png'),
  PCT: require('./assets/factory-sm.png'),
  NX: require('./assets/factory-sm.png'),
  PWR: require('./assets/factory-sm.png'),
  QRHC: require('./assets/factory-sm.png'),
  RNW: require('./assets/factory-sm.png'),
  REFR: require('./assets/factory-sm.png'),
  RETO: require('./assets/factory-sm.png'),
  SPTN: require('./assets/factory-sm.png'),
  LEDS: require('./assets/factory-sm.png'),
  SHLS: require('./assets/factory-sm.png'),
  SEDG: require('./assets/factory-sm.png'),
  SLDP: require('./assets/factory-sm.png'),
  SCCO: require('./assets/factory-sm.png'),
  SPRU: require('./assets/factory-sm.png'),
  STKH: require('./assets/factory-sm.png'),
  STLD: require('./assets/factory-sm.png'),
  NOVA: require('./assets/factory-sm.png'),
  STKL: require('./assets/factory-sm.png'),
  RUN: require('./assets/factory-sm.png'),
  SWVL: require('./assets/factory-sm.png'),
  TGLS: require('./assets/factory-sm.png'),
  HAIN: require('./assets/factory-sm.png'),
  TMC: require('./assets/factory-sm.png'),
  BLD: require('./assets/factory-sm.png'),
  TPIC: require('./assets/factory-sm.png'),
  URG: require('./assets/factory-sm.png'),
  VMI: require('./assets/factory-sm.png'),
  WCN: require('./assets/factory-sm.png'),
  WM: require('./assets/factory-sm.png'),
  WTS: require('./assets/factory-sm.png'),
  WWR: require('./assets/factory-sm.png'),
  WLDN: require('./assets/factory-sm.png'),
  XOS: require('./assets/factory-sm.png'),
  XPEV: require('./assets/factory-sm.png'),
};

const cryptoSmallIconMap = {
  ETH: require('./assets/eth-sm.png'),
  DOGE: require('./assets/doge-sm.png'),
  BTC: require('./assets/btc-sm.png'),
  AVAX: require('./assets/avax-sm.png'),
  XLM: require('./assets/xlm-sm.png'),
  BCH: require('./assets/bch-sm.png'),
  LINK: require('./assets/link-sm.png'),
  UNI: require('./assets/uni-sm.png'),
  ETC: require('./assets/etc-sm.png'),
  XTZ: require('./assets/xtz-sm.png'),
  COMP: require('./assets/comp-sm.png'),
  AAVE: require('./assets/aave-sm.png'),
  LTC: require('./assets/ltc-sm.png'),
  default: require('./assets/grin-sm.png'),
  XRP: require('./assets/xrp-sm.png'),
  POL: require('./assets/matic-sm.png'),
  SOL: require('./assets/sol-sm.png'),
  ADA: require('./assets/ada-sm.png'),
  DOT: require('./assets/dot-sm.png'),
  SHIB: require('./assets/shib-sm.png'),
  VFS: require('./assets/factory-sm.png'),
  SMCI: require('./assets/factory-sm.png'),
  IAC: require('./assets/factory-sm.png'),
  NOW: require('./assets/factory-sm.png'),
  AAL: require('./assets/factory-sm.png'),
  HD: require('./assets/factory-sm.png'),
  WDAY: require('./assets/factory-sm.png'),
  CHWY: require('./assets/factory-sm.png'),
  META: require('./assets/factory-sm.png'),
  NFLX: require('./assets/factory-sm.png'),
  DAL: require('./assets/factory-sm.png'),
  NIO: require('./assets/factory-sm.png'),
  CRM: require('./assets/factory-sm.png'),
  AAPL: require('./assets/factory-sm.png'),
  MSFT: require('./assets/factory-sm.png'),
  HOOD: require('./assets/factory-sm.png'),
  RBLX: require('./assets/factory-sm.png'),
  RIVN: require('./assets/factory-sm.png'),
  KOSS: require('./assets/factory-sm.png'),
  GME: require('./assets/factory-sm.png'),
  XOM: require('./assets/factory-sm.png'),
  HLX: require('./assets/factory-sm.png'),
  TSM: require('./assets/factory-sm.png'),
  NVDA: require('./assets/factory-sm.png'),
  AI: require('./assets/factory-sm.png'),
  SAP: require('./assets/factory-sm.png'),
  AMD: require('./assets/factory-sm.png'),
  QCOM: require('./assets/factory-sm.png'),
  PANW: require('./assets/factory-sm.png'),
  EQIX: require('./assets/factory-sm.png'),
  GOOG: require('./assets/factory-sm.png'),
  COP: require('./assets/factory-sm.png'),
  UUUU: require('./assets/factory-sm.png'),
  NET: require('./assets/factory-sm.png'),
  MANH: require('./assets/factory-sm.png'),
  FROG: require('./assets/factory-sm.png'),
  PD: require('./assets/factory-sm.png'),
  FTHM: require('./assets/factory-sm.png'),
  WMT: require('./assets/factory-sm.png'),
  LLY: require('./assets/factory-sm.png'),
  CRSP: require('./assets/factory-sm.png'),
  AUST: require('./assets/factory-sm.png'),
  GXO: require('./assets/factory-sm.png'),
  AIT: require('./assets/factory-sm.png'),
  MU: require('./assets/factory-sm.png'),
  SNOW: require('./assets/factory-sm.png'),
  TEAM: require('./assets/factory-sm.png'),
  DOCN: require('./assets/factory-sm.png'),
  ZS: require('./assets/factory-sm.png'),
  OKTA: require('./assets/factory-sm.png'),
  SQ: require('./assets/factory-sm.png'),
  TALO: require('./assets/factory-sm.png'),
  INFY: require('./assets/factory-sm.png'),
  CDNS: require('./assets/factory-sm.png'),
  SNPS: require('./assets/factory-sm.png'),
  AMX: require('./assets/factory-sm.png'),
  AMAT: require('./assets/factory-sm.png'),
  CEG: require('./assets/factory-sm.png'),
  PLUG: require('./assets/factory-sm.png'),
  LIN: require('./assets/factory-sm.png'),
  BLDP: require('./assets/factory-sm.png'),
  IREN: require('./assets/factory-sm.png'),
  ADBE: require('./assets/factory-sm.png'),
  CLSK: require('./assets/factory-sm.png'),
  MARA: require('./assets/factory-sm.png'),
  RIOT: require('./assets/factory-sm.png'),
  MSTR: require('./assets/factory-sm.png'),
  CLCO: require('./assets/factory-sm.png'),
  JKS: require('./assets/factory-sm.png'),
  SPY: require('./assets/factory-sm.png'),
  VOO: require('./assets/factory-sm.png'),
  IVV: require('./assets/factory-sm.png'),
  VTI: require('./assets/factory-sm.png'),
  QQQ: require('./assets/factory-sm.png'),
  VUG: require('./assets/factory-sm.png'),
  VEA: require('./assets/factory-sm.png'),
  VTV: require('./assets/factory-sm.png'),
  IEFA: require('./assets/factory-sm.png'),
  AGG: require('./assets/factory-sm.png'),
  BND: require('./assets/factory-sm.png'),
  IWF: require('./assets/factory-sm.png'),
  IJH: require('./assets/factory-sm.png'),
  VIG: require('./assets/factory-sm.png'),
  IJR: require('./assets/factory-sm.png'),
  VWO: require('./assets/factory-sm.png'),
  IEMG: require('./assets/factory-sm.png'),
  GLD: require('./assets/factory-sm.png'),
  VGT: require('./assets/factory-sm.png'),
  VXUS: require('./assets/factory-sm.png'),
  VO: require('./assets/factory-sm.png'),
  XLK: require('./assets/factory-sm.png'),
  IWM: require('./assets/factory-sm.png'),
  RSP: require('./assets/factory-sm.png'),
  SCHD: require('./assets/factory-sm.png'),
  NEP: require('./assets/factory-sm.png'),
  SMXT: require('./assets/factory-sm.png'),
  UMAC: require('./assets/factory-sm.png'),
  MOBX: require('./assets/factory-sm.png'),
  OPTT: require('./assets/factory-sm.png'),
  FLUX: require('./assets/factory-sm.png'),
  WAVE: require('./assets/factory-sm.png'),
  NNE: require('./assets/factory-sm.png'),
  UEC: require('./assets/factory-sm.png'),
  CCJ: require('./assets/factory-sm.png'),
  DDD: require('./assets/factory-sm.png'),
  PATH: require('./assets/factory-sm.png'),
  SYM: require('./assets/factory-sm.png'),
  PYPL: require('./assets/factory-sm.png'),
  GTLB: require('./assets/factory-sm.png'),
  PAC: require('./assets/factory-sm.png'),
  GAMB: require('./assets/factory-sm.png'),
  BBCP: require('./assets/factory-sm.png'),
  LAAC: require('./assets/factory-sm.png'),
  LAC: require('./assets/factory-sm.png'),
  APD: require('./assets/factory-sm.png'),
  HYLN: require('./assets/factory-sm.png'),
  HTOO: require('./assets/factory-sm.png'),
  HAL: require('./assets/factory-sm.png'),
  AAON: require('./assets/factory-sm.png'),
  AYI: require('./assets/factory-sm.png'),
  ADSE: require('./assets/factory-sm.png'),
  ADN: require('./assets/factory-sm.png'),
  AMPS: require('./assets/factory-sm.png'),
  AMRC: require('./assets/factory-sm.png'),
  APOG: require('./assets/factory-sm.png'),
  AQMS: require('./assets/factory-sm.png'),
  ACHR: require('./assets/factory-sm.png'),
  ARRY: require('./assets/factory-sm.png'),
  AY: require('./assets/factory-sm.png'),
  RERE: require('./assets/factory-sm.png'),
  BECN: require('./assets/factory-sm.png'),
  BYND: require('./assets/factory-sm.png'),
  BLNK: require('./assets/factory-sm.png'),
  BWEN: require('./assets/factory-sm.png'),
  BEPC: require('./assets/factory-sm.png'),
  CSIQ: require('./assets/factory-sm.png'),
  GOEV: require('./assets/factory-sm.png'),
  CARR: require('./assets/factory-sm.png'),
  CBAT: require('./assets/factory-sm.png'),
  CENN: require('./assets/factory-sm.png'),
  LEU: require('./assets/factory-sm.png'),
  CHPT: require('./assets/factory-sm.png'),
  CWEN: require('./assets/factory-sm.png'),
  FIX: require('./assets/factory-sm.png'),
  CMC: require('./assets/factory-sm.png'),
  CRKN: require('./assets/factory-sm.png'),
  CSWI: require('./assets/factory-sm.png'),
  DNMR: require('./assets/factory-sm.png'),
  DQ: require('./assets/factory-sm.png'),
  DNN: require('./assets/factory-sm.png'),
  ETN: require('./assets/factory-sm.png'),
  NRGV: require('./assets/factory-sm.png'),
  ENPH: require('./assets/factory-sm.png'),
  EOSE: require('./assets/factory-sm.png'),
  GWH: require('./assets/factory-sm.png'),
  EVGO: require('./assets/factory-sm.png'),
  EZGO: require('./assets/factory-sm.png'),
  FSLR: require('./assets/factory-sm.png'),
  FLNC: require('./assets/factory-sm.png'),
  FRGT: require('./assets/factory-sm.png'),
  FREY: require('./assets/factory-sm.png'),
  FTCI: require('./assets/factory-sm.png'),
  FF: require('./assets/factory-sm.png'),
  GATO: require('./assets/factory-sm.png'),
  GFL: require('./assets/factory-sm.png'),
  HDSN: require('./assets/factory-sm.png'),
  HYZN: require('./assets/factory-sm.png'),
  RBA: require('./assets/factory-sm.png'),
  IBP: require('./assets/factory-sm.png'),
  IONR: require('./assets/factory-sm.png'),
  IPX: require('./assets/factory-sm.png'),
  ITRI: require('./assets/factory-sm.png'),
  JZXN: require('./assets/factory-sm.png'),
  JOBY: require('./assets/factory-sm.png'),
  JCI: require('./assets/factory-sm.png'),
  KNDI: require('./assets/factory-sm.png'),
  KULR: require('./assets/factory-sm.png'),
  LSF: require('./assets/factory-sm.png'),
  LII: require('./assets/factory-sm.png'),
  LI: require('./assets/factory-sm.png'),
  LICY: require('./assets/factory-sm.png'),
  LTBR: require('./assets/factory-sm.png'),
  LKQ: require('./assets/factory-sm.png'),
  LOOP: require('./assets/factory-sm.png'),
  LYTS: require('./assets/factory-sm.png'),
  MAXN: require('./assets/factory-sm.png'),
  MVST: require('./assets/factory-sm.png'),
  MNTK: require('./assets/factory-sm.png'),
  MP: require('./assets/factory-sm.png'),
  MULN: require('./assets/factory-sm.png'),
  NXE: require('./assets/factory-sm.png'),
  NKLA: require('./assets/factory-sm.png'),
  NIU: require('./assets/factory-sm.png'),
  NUE: require('./assets/factory-sm.png'),
  SMR: require('./assets/factory-sm.png'),
  OTLY: require('./assets/factory-sm.png'),
  NVVE: require('./assets/factory-sm.png'),
  ORGN: require('./assets/factory-sm.png'),
  OESX: require('./assets/factory-sm.png'),
  ORA: require('./assets/factory-sm.png'),
  PESI: require('./assets/factory-sm.png'),
  PLPC: require('./assets/factory-sm.png'),
  PCT: require('./assets/factory-sm.png'),
  NX: require('./assets/factory-sm.png'),
  PWR: require('./assets/factory-sm.png'),
  QRHC: require('./assets/factory-sm.png'),
  RNW: require('./assets/factory-sm.png'),
  REFR: require('./assets/factory-sm.png'),
  RETO: require('./assets/factory-sm.png'),
  SPTN: require('./assets/factory-sm.png'),
  LEDS: require('./assets/factory-sm.png'),
  SHLS: require('./assets/factory-sm.png'),
  SEDG: require('./assets/factory-sm.png'),
  SLDP: require('./assets/factory-sm.png'),
  SCCO: require('./assets/factory-sm.png'),
  SPRU: require('./assets/factory-sm.png'),
  STKH: require('./assets/factory-sm.png'),
  STLD: require('./assets/factory-sm.png'),
  NOVA: require('./assets/factory-sm.png'),
  STKL: require('./assets/factory-sm.png'),
  RUN: require('./assets/factory-sm.png'),
  SWVL: require('./assets/factory-sm.png'),
  TGLS: require('./assets/factory-sm.png'),
  HAIN: require('./assets/factory-sm.png'),
  TMC: require('./assets/factory-sm.png'),
  BLD: require('./assets/factory-sm.png'),
  TPIC: require('./assets/factory-sm.png'),
  URG: require('./assets/factory-sm.png'),
  VMI: require('./assets/factory-sm.png'),
  WCN: require('./assets/factory-sm.png'),
  WM: require('./assets/factory-sm.png'),
  WTS: require('./assets/factory-sm.png'),
  WWR: require('./assets/factory-sm.png'),
  WLDN: require('./assets/factory-sm.png'),
  XOS: require('./assets/factory-sm.png'),
  XPEV: require('./assets/factory-sm.png'),
};

const investSettingsExplanations = {
  BOTH: 'SIDEWAYS MARKET🌊🌊🌊',
  BULL: 'BULL MARKET📈',
  BEAR: 'BEAR MARKET📉',
  NONE: 'DO NOT INVEST',
};

const CRYPTO_CONFIG = {
  names: [
    'BTC',
    'ETH',
    'DOGE',
    'AVAX',
    'BCH',
    'UNI',
    'LINK',
    'XLM',
    'ETC',
    'LTC',
    'COMP',
    'AAVE',
    'XTZ',
    'XRP',
    'POL',
    'SOL',
    'ADA',
    'DOT',
    'SHIB',
    'VFS',
    'SMCI',
    'IAC',
    'NOW',
    'AAL',
    'HD',
    'WDAY',
    'DAL',
    'CHWY',
    'META',
    'NFLX',
    'DAL',
    'NIO',
    'CRM',
    'AAPL',
    'MSFT',
    'HOOD',
    'RBLX',
    'RIVN',
    'KOSS',
    'GME',
    'XOM',
    'HLX',
    'TSM',
    'NVDA',
    'AI',
    'SAP',
    'AMD',
    'QCOM',
    'PANW',
    'EQIX',
    'GOOG',
    'COP',
    'UUUU',
    'NET',
    'MANH',
    'FROG',
    'PD',
    'FTHM',
    'WMT',
    'LLY',
    'CRSP',
    'AUST',
    'GXO',
    'AIT',
    'MU',
    'SNOW',
    'TEAM',
    'DOCN',
    'ZS',
    'OKTA',
    'SQ',
    'TALO',
    'INFY',
    'CDNS',
    'SNPS',
    'AMX',
    'AMAT',
    'CEG',
    'PLUG',
    'LIN',
    'BLDP',
    'IREN',
    'ADBE',
    'CLSK',
    'MARA',
    'RIOT',
    'MSTR',
    'CLCO',
    'JKS',
    'SPY',
    'VOO',
    'IVV',
    'VTI',
    'QQQ',
    'VUG',
    'VEA',
    'VTV',
    'IEFA',
    'AGG',
    'BND',
    'IWF',
    'IJH',
    'VIG',
    'IJR',
    'VWO',
    'IEMG',
    'GLD',
    'VGT',
    'VXUS',
    'VO',
    'XLK',
    'IWM',
    'RSP',
    'SCHD',
    'NEP',
    'SMXT',
    'UMAC',
    'MOBX',
    'OPTT',
    'FLUX',
    'WAVE',
    'NNE',
    'UEC',
    'CCJ',
    'DDD',
    'PATH',
    'SYM',
    'PYPL',
    'GTLB',
    'PAC',
    'GAMB',
    'BBCP',
    'LAAC',
    'LAC',
    'APD',
    'HYLN',
    'HTOO',
    'HAL',
    'AAON',
    'AYI',
    'ADSE',
    'ADN',
    'AMPS',
    'AMRC',
    'APOG',
    'AQMS',
    'ACHR',
    'ARRY',
    'AY',
    'RERE',
    'BECN',
    'BYND',
    'BLNK',
    'BWEN',
    'BEPC',
    'CSIQ',
    'GOEV',
    'CARR',
    'CBAT',
    'CENN',
    'LEU',
    'CHPT',
    'CWEN',
    'FIX',
    'CMC',
    'CRKN',
    'CSWI',
    'DNMR',
    'DQ',
    'DNN',
    'ETN',
    'NRGV',
    'ENPH',
    'EOSE',
    'GWH',
    'EVGO',
    'EZGO',
    'FSLR',
    'FLNC',
    'FLUX',
    'FRGT',
    'FREY',
    'FTCI',
    'FF',
    'GATO',
    'GFL',
    'HDSN',
    'HYZN',
    'RBA',
    'IBP',
    'IONR',
    'IPX',
    'ITRI',
    'JZXN',
    'JOBY',
    'JCI',
    'KNDI',
    'KULR',
    'LSF',
    'LII',
    'LI',
    'LICY',
    'LTBR',
    'LKQ',
    'LOOP',
    'LYTS',
    'MAXN',
    'MVST',
    'MNTK',
    'MP',
    'MULN',
    'NXE',
    'NKLA',
    'NIU',
    'NUE',
    'SMR',
    'OTLY',
    'NVVE',
    'ORGN',
    'OESX',
    'ORA',
    'PESI',
    'PLPC',
    'PCT',
    'NX',
    'PWR',
    'QRHC',
    'RNW',
    'REFR',
    'RETO',
    'SPTN',
    'LEDS',
    'SHLS',
    'SEDG',
    'SLDP',
    'SCCO',
    'SPRU',
    'STKH',
    'STLD',
    'NOVA',
    'STKL',
    'RUN',
    'SWVL',
    'TGLS',
    'HAIN',
    'TMC',
    'BLD',
    'TPIC',
    'URG',
    'VMI',
    'WCN',
    'WM',
    'WTS',
    'WWR',
    'WLDN',
    'XOS',
    'XPEV',
  ],
  fullNames: {
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    DOGE: 'Dogecoin',
    AVAX: 'Avalanche',
    BCH: 'Bitcoin Cash',
    UNI: 'Uniswap',
    LINK: 'Chainlink',
    XLM: 'Stellar Lumens',
    ETC: 'Ethereum Classic',
    LTC: 'Litecoin',
    COMP: 'Compound',
    AAVE: 'Aave',
    XTZ: 'Tezos',
    XRP: 'XRP',
    POL: 'Polygon',
    SOL: 'Solana',
    ADA: 'Cardano',
    DOT: 'Pokadot',
    SHIB: 'Shina Inu',
    VFS: 'VinFast Auto',
    SMCI: 'Super Micro Computer',
    IAC: 'IAC/InterActiveCorp',
    NOW: 'ServiceNow',
    AAL: 'American Airlines',
    HD: 'The Home Depot',
    WDAY: 'Workday',
    DAL: 'Delta Air Lines',
    CHWY: 'Chewy',
    META: 'Meta Platforms',
    NFLX: 'Netflix',
    NIO: 'NIO',
    CRM: 'Salesforce',
    AAPL: 'Apple',
    MSFT: 'Microsoft Corporation',
    HOOD: 'Robinhood Markets',
    RBLX: 'Roblox Corporation',
    RIVN: 'Rivian Automotive',
    KOSS: 'Koss Corporation',
    GME: 'GameStop Corp.',
    XOM: 'Exxon Mobil',
    HLX: 'Helix Energy',
    TSM: 'Taiwan Semiconductor Manufacturing Company',
    NVDA: 'NVIDIA',
    AI: 'C3.ai',
    SAP: 'SAP',
    AMD: 'Advanced Micro Devices',
    QCOM: 'Qualcomm',
    PANW: 'Palo Alto Networks',
    EQIX: 'Equinix',
    GOOG: 'Alphabet (Google)',
    COP: 'ConocoPhillips',
    UUUU: 'Energy Fuels',
    NET: 'Cloudflare',
    MANH: 'Manhattan Associates',
    FROG: 'Jfrog',
    PD: 'PagerDuty',
    FTHM: 'Fathom Digital Manufacturing',
    WMT: 'Walmart',
    LLY: 'Eli Lilly',
    CRSP: 'CRISPR Therapeutics',
    AUST: 'Austin Gold Corp',
    GXO: 'GXO Logistics',
    AIT: 'Applied Industrial Tech',
    MU: 'Micron Technology',
    SNOW: 'Snowflake Inc.',
    TEAM: 'Atlassian Corporation',
    DOCN: 'Digital Ocean.',
    ZS: 'Zscaler',
    OKTA: 'Okta',
    SQ: 'Block',
    TALO: 'Talos Energy',
    INFY: 'Infosys',
    CDNS: 'Cadence Design Systems',
    SNPS: 'Synopsys',
    AMX: 'America Movil',
    AMAT: 'Applied Materials',
    CEG: 'Constellation Energy',
    PLUG: 'Plug Power',
    LIN: 'Linde',
    BLDP: 'Ballard Power Systems',
    IREN: 'Iris Energy.',
    ADBE: 'Adobe',
    CLSK: 'CleanSpark',
    MARA: 'Marathon Digital Holdings',
    RIOT: 'Riot Platforms',
    MSTR: 'MicroStrategy',
    CLCO: 'Cool Company',
    JKS: 'JinkoSolar Holding',
    SPY: 'SPDR S&P 500 ETF Trust',
    VOO: 'Vanguard S&P 500 ETF',
    IVV: 'iShares Core S&P 500 ETF',
    VTI: 'Vanguard Total Stock Market ETF',
    QQQ: 'Invesco QQQ Trust Series I',
    VUG: 'Vanguard Growth ETF',
    VEA: 'Vanguard FTSE Developed Markets ETF',
    VTV: 'Vanguard Value ETF',
    IEFA: 'iShares Core MSCI EAFE ETF',
    AGG: 'iShares Core U.S. Aggregate Bond ETF',
    BND: 'Vanguard Total Bond Market ETF',
    IWF: 'iShares Russell 1000 Growth ETF',
    IJH: 'iShares Core S&P Mid-Cap ETF',
    VIG: 'Vanguard Dividend Appreciation ETF',
    IJR: 'iShares Core S&P Small-Cap ETF',
    VWO: 'Vanguard FTSE Emerging Markets ETF',
    IEMG: 'iShares Core MSCI Emerging Markets ETF',
    GLD: 'SPDR Gold Shares',
    VGT: 'Vanguard Information Technology ETF',
    VXUS: 'Vanguard Total International Stock ETF',
    VO: 'Vanguard Mid-Cap ETF',
    XLK: 'Technology Select Sector SPDR ETF',
    IWM: 'iShares Russell 2000 ETF',
    RSP: 'Invesco S&P 500 Equal Weight ETF',
    SCHD: 'Schwab US Dividend Equity ETF',
    NEP: 'NextEra Energy Partners',
    SMXT: 'Semtech Corporation',
    UMAC: 'Uranium Minerals Inc.',
    MOBX: 'MobiCard Inc.',
    OPTT: 'Ocean Power Technologies',
    FLUX: 'Flux Power Holdings',
    WAVE: 'Wave Life Sciences',
    NNE: 'Noble New Energy',
    UEC: 'Uranium Energy Corp',
    CCJ: 'Cameco Corporation',
    DDD: '3D Systems Corporation',
    PATH: 'Pathfinder Ventures',
    SYM: 'Symbotic',
    PYPL: 'PayPal Holdings',
    GTLB: 'GitLab Inc.',
    PAC: 'PACCAR Inc.',
    GAMB: 'Gambro AB',
    BBCP: 'Concrete Pumping Holdings',
    LAAC: 'Ladder Capital Corp.',
    LAC: 'Lithium Americas Corp.',
    APD: 'Air Products and Chemicals',
    HYLN: 'Hyliion Holdings Corp.',
    HTOO: 'H2O Innovation',
    HAL: 'Halliburton',
    AAON: 'AAON Inc.',
    AYI: 'Acuity Brands, Inc.',
    ADSE: 'Advanced Surgical Technologies',
    ADN: 'Adena Ventures',
    AMPS: 'Amplify Energy Corp.',
    AMRC: 'Ameresco, Inc.',
    APOG: 'Apogee Enterprises',
    AQMS: 'Aqua Metals',
    ACHR: 'Archer Aviation',
    ARRY: 'Array Technologies',
    AY: 'Atlantica Sustainable Infrastructure',
    RERE: 'Renewable Energy Group',
    BECN: 'Beacon Roofing Supply',
    BYND: 'Beyond Meat',
    BLNK: 'Blink Charging',
    BWEN: 'Broadwind Inc.',
    BEPC: 'Brookfield Renewable Partners',
    CSIQ: 'Canadian Solar',
    GOEV: 'Lordstown Motors Corp.',
    CARR: 'Carrier Global Corporation',
    CBAT: 'CBAK Energy Technology',
    CENN: 'Cenntro Electric Group',
    LEU: 'Centrus Energy Corp',
    CHPT: 'ChargePoint',
    CWEN: 'Clearway Energy',
    FIX: 'Comfort Systems USA',
    CMC: 'Commercial Metals Company',
    CRKN: 'Cure Pharmaceutical',
    CSWI: 'CSW Industrials',
    DNMR: 'Danimer Scientific',
    DQ: 'Daqo New Energy',
    DNN: 'Denison Mines',
    ETN: 'Eaton Corporation',
    NRGV: 'NRG Energy',
    ENPH: 'Enphase Energy',
    EOSE: 'Enovix Corporation',
    GWH: 'EVgo Inc.',
    EVGO: 'EVgo',
    EZGO: 'EZGO Technologies',
    FSLR: 'First Solar',
    FLNC: 'Fluence Energy',
    FRGT: 'Freight Technologies',
    FREY: 'FREYR Battery',
    FTCI: 'FTAC Olympus Acquisition Corp.',
    FF: 'FutureFuel Corp.',
    GATO: 'Gatos Silver',
    GFL: 'GFL Environmental',
    HDSN: 'Hudson Technologies',
    HYZN: 'Hyliion',
    RBA: 'Ritchie Bros. Auctioneers',
    IBP: 'Installed Building Products',
    IONR: 'IonQ',
    IPX: 'ImageXpress',
    ITRI: 'Itron, Inc.',
    JZXN: 'Jiuzi Holdings',
    JOBY: 'Joby Aviation',
    JCI: 'Johnson Controls',
    KNDI: 'Kandi Technologies',
    KULR: 'KULR Technology Group',
    LSF: 'Laird Superfood',
    LII: 'Lennox International',
    LI: 'Li Auto',
    LICY: 'Li-Cycle Holdings',
    LTBR: 'Lightbridge Corporation',
    LKQ: 'LKQ Corporation',
    LOOP: 'Loop Industries',
    LYTS: 'Lindsay Corporation',
    MAXN: 'Maxeon Solar Technologies',
    MVST: 'Microvast',
    MNTK: 'Montauk Renewables',
    MP: 'MP Materials',
    MULN: 'Mullen Automotive',
    NXE: 'NexGen Energy',
    NKLA: 'Nikola Corporation',
    NIU: 'Niu Technologies',
    NUE: 'Nucor Corporation',
    SMR: 'Small Modular Reactor',
    OTLY: 'Oatly',
    NVVE: 'Nuvve Corporation',
    ORGN: 'Origin Materials',
    OESX: 'Orion Energy Systems',
    ORA: 'Ormat Technologies',
    PESI: 'Pioneer Energy Services',
    PLPC: 'Preformed Line Products',
    PCT: 'PCTEL',
    NX: 'Quanex Building Products',
    PWR: 'Quanta Services',
    QRHC: 'Quest Resource Holding',
    RNW: 'Renewable Energy Group',
    REFR: 'Research Frontiers',
    RETO: 'ReTo Eco-Solutions',
    SPTN: 'SpartanNash',
    LEDS: 'SemiLEDs Corporation',
    SHLS: 'Shoals Technologies',
    SEDG: 'SolarEdge Technologies',
    SLDP: 'Solid Power',
    SCCO: 'Southern Copper',
    SPRU: 'Spruce Biosciences',
    STKH: 'Sustainable Opportunities Acquisition',
    STLD: 'Steel Dynamics',
    NOVA: 'Sunnova Energy',
    STKL: 'SunOpta',
    RUN: 'Sunrun',
    SWVL: 'Swvl Holdings',
    TGLS: 'Tegna',
    HAIN: 'The Hain Celestial Group',
    TMC: 'The Metals Company',
    BLD: 'TopBuild',
    TPIC: 'TPI Composites',
    URG: 'Ur-Energy',
    VMI: 'Valmont Industries',
    WCN: 'Waste Connections',
    WM: 'Waste Management',
    WTS: 'Waterous Energy Fund',
    WWR: 'Westwater Resources',
    WLDN: 'WestLand Resources',
    XOS: 'Xos Inc.',
    XPEV: 'XPeng Motors',
  },
};
let RELEVANCY_RANKING = [
  'BTC',
  'ETH',
  'AAPL',
  'MSFT',
  'GOOG',
  'AMZN',
  'META',
  'TSM',
  'NVDA',
  'SPY',
  'VOO',
  'QQQ',
  'VTI',
  'IVV',
  'VGT',
  'BND',
  'VWO',
  'XRP',
  'ADA',
  'SOL',
  'LTC',
  'DOGE',
  'BCH',
  'XLM',
  'LINK',
  'UNI',
  'AAVE',
  'XTZ',
  'DOT',
  'SHIB',
  'NIO',
  'PYPL',
  'CRM',
  'NFLX',
  'RBLX',
  'HD',
  'WMT',
  'COP',
  'CVX',
  'QCOM',
  'INFY',
  'SNOW',
  'GOEV',
  'PLUG',
  'ENPH',
  'FSLR',
  'IONR',
  'MARA',
  'RIOT',
  'MSTR',
  'RSP',
  'VIG',
  'SMCI',
  'VFS',
  'RIVN',
  'CLSK',
  'BYND',
  'BLDP',
  'BLNK',
  'BWEN',
  'NOVA',
  'FROG',
  'GTLB',
  'SMXT',
  'UMAC',
  'NEP',
  'FLUX',
  'NNE',
  'LEDS',
  'RETO',
  'OESX',
  'PESI',
  'PCT',
  'FREY',
  'FRGT',
  'CLCO',
  'CARR',
  'CBAT',
  'LEU',
  'ITRI',
  'GOEV',
  'AI',
  'AMD',
  'QCOM',
  'SAP',
  'PANW',
  'EQIX',
  'XOM',
  'TSM',
  'KOSS',
  'GME',
  'AI',
  'FROG',
  'WMT',
  'LLY',
  'CRSP',
  'GXO',
  'AIT',
  'SNOW',
  'TEAM',
  'DOCN',
  'ZS',
  'OKTA',
  'SQ',
  'TALO',
  'INFY',
  'CDNS',
  'SNPS',
  'AMX',
  'AMAT',
  'CEG',
  'PLUG',
  'LIN',
  'BLDP',
  'IREN',
  'ADBE',
  'CLSK',
  'MARA',
  'RIOT',
  'MSTR',
  'CLCO',
  'JKS',
  'SPY',
  'VWO',
  'VIG',
  'IJH',
  'VTI',
  'VUG',
  'VTV',
  'VEA',
  'IEFA',
  'AGG',
  'BND',
  'IWF',
  'IJH',
  'IEMG',
  'GLD',
  'VGT',
  'VXUS',
  'VO',
  'XLK',
  'IWM',
  'RSP',
  'SCHD',
  'NEP',
  'MOBX',
  'OPTT',
  'WAVE',
  'NNE',
  'UEC',
  'CCJ',
  'DDD',
  'PATH',
  'SYM',
  'PYPL',
  'GTLB',
  'PAC',
  'GAMB',
  'BBCP',
  'LAAC',
  'LAC',
  'APD',
  'HYLN',
  'HTOO',
  'HAL',
  'AAON',
  'AYI',
  'ADSE',
  'ADN',
  'AMPS',
  'AMRC',
  'APOG',
  'AQMS',
  'ACHR',
  'ARRY',
  'AY',
  'RERE',
  'BECN',
  'BYND',
  'BLNK',
  'BWEN',
  'BEPC',
  'CSIQ',
  'GOEV',
  'CARR',
  'CBAT',
  'CENN',
  'LEU',
  'CHPT',
  'CWEN',
  'FIX',
  'CMC',
  'CRKN',
  'CSWI',
  'DNMR',
  'DQ',
  'DNN',
  'ETN',
  'NRGV',
  'ENPH',
  'EOSE',
  'GWH',
  'EVGO',
  'EZGO',
  'FSLR',
  'FLNC',
  'FLUX',
  'FRGT',
  'FREY',
  'FTCI',
  'FF',
  'GATO',
  'GFL',
  'HDSN',
  'HYZN',
  'RBA',
  'IBP',
  'IONR',
  'IPX',
  'ITRI',
  'JZXN',
  'JOBY',
  'JCI',
  'KNDI',
  'KULR',
  'LSF',
  'LII',
  'LI',
  'LICY',
  'LTBR',
  'LKQ',
  'LOOP',
  'LYTS',
  'MAXN',
  'MVST',
  'MNTK',
  'MP',
  'MULN',
  'NXE',
  'NKLA',
  'NIU',
  'NUE',
  'SMR',
  'OTLY',
  'NVVE',
  'ORGN',
  'OESX',
  'ORA',
  'PESI',
  'PLPC',
  'PCT',
  'NX',
  'PWR',
  'QRHC',
  'RNW',
  'REFR',
  'RETO',
  'SPTN',
  'LEDS',
  'SHLS',
  'SEDG',
  'SLDP',
  'SCCO',
  'SPRU',
  'STKH',
  'STLD',
  'NOVA',
  'STKL',
  'RUN',
  'SWVL',
  'TGLS',
  'HAIN',
  'TMC',
  'BLD',
  'TPIC',
  'URG',
  'VMI',
  'WCN',
  'WM',
  'WTS',
  'WWR',
  'WLDN',
  'XOS',
  'XPEV',
];
let RH_SUPPORTED_LOGISTICS_STOCKS = new Set(['GXO']);
let RH_SUPPORTED_OIL_STOCKS = new Set(['XOM', 'COP', 'TALO', 'HLX', 'HAL']);
let RH_SUPPORTED_MINING_STOCKS = new Set([
  'XOM', // ExxonMobil (Oil & gas, involved in extraction)
  'COP', // ConocoPhillips (Oil & gas, involved in extraction)
  'UUUU', // Energy Fuels Inc. (Uranium mining)
  'CCJ', // Cameco Corporation (Uranium mining)
  'LAC', // Lithium Americas Corp. (Lithium mining)
  'LAAC',
  'NUE', // Nucor Corporation (Steel production, related to mining)
  'SMR', // SMR (Uranium mining, nuclear fuel)
  'NXE', // NexGen Energy Ltd. (Uranium mining)
  'MP', // MP Materials Corp. (Rare earth mining)
  'RBA', // Ritchie Bros. Auctioneers (Heavy equipment used in mining)
  'TALO', // Talos Energy Inc. (Oil and gas exploration)
  'KNDI', // Kandi Technologies (Mining of rare minerals for EVs)
  'CLCO', // Clear Channel Outdoor (Exposure to minerals in outdoor equipment)
  'GATO', // Gatos Silver Inc. (Silver mining)
  'DNN', // Denison Mines Corp. (Uranium mining)
  'NRGV', // NRG Energy Inc. (Energy generation with some mining exposure)
  'IONR', // ION Energy Ltd. (Lithium exploration)
  'SMCI', // Super Micro Computer (Has exposure to mining industry tech)
  'RIVN', // Rivian (EV manufacturer, related to rare minerals)
  'CSIQ', // Canadian Solar Inc. (Solar energy, may involve mining materials)
  'KULR', // KULR Technology Group (Rare materials for thermal management)
]);
let RH_SUPPORTED_RENEWABLES_STOCKS = new Set([
  'PLUG',
  'BLDP',
  'CEG',
  'ENPH',
  'FSLR',
  'SEDG',
  'RUN',
  'BEPC',
  'CSIQ',
  'SPTN',
  'NEP',
  'CWEN',
  'NRGV',
  'HYLN',
  'BLNK',
  'CHPT',
  'EVGO',
  'NOVA',
  'RUN',
  'VMI',
]);
let RH_SUPPORTED_AIRLINE_STOCKS = new Set(['DAL', 'AAL']);
let RH_SUPPORTED_CLIMATE_CHANGE_STOCKS = new Set([
  'PLUG', // Plug Power
  'AMRC', // Ameresco
  'AQMS', // Aqua Metals
  'BLDP', // Ballard Power Systems
  'BLNK', // Blink Charging
  'CSIQ', // Canadian Solar
  'CHPT', // ChargePoint Holdings
  'CWEN', // Clearway Energy
  'ENPH', // Enphase Energy
  'EOSE', // Eos Energy Enterprises
  'EVGO', // EVgo
  'EZGO', // EZGO Technologies
  'FSLR', // First Solar
  'FREY', // FREYR Battery
  'FF', // Fusion Fuel Green
  'GATO', // Gatos Silver, Inc.
  'GFL', // GFL Environmental
  'HYZN', // Hyzon Motors Inc.
  'IONR', // Ioneer Ltd
  'IPX', // IperionX Limited
  'JKS', // JinkoSolar
  'JOBY', // Joby Aviation, Inc.
  'KNDI', // Kandi Technologies Group
  'KULR', // KULR Technology Group
  'LICY', // Li-Cycle Holdings Corp
  'LAC', // Lithium Americas Corp
  'LAAC',
  'MAXN', // Maxeon Solar Technologies
  'MP', // MP Materials Corp.
  'NEP', // NextEra Energy Partners, LP
  'NIO', // NIO
  'NUE', // Nucor Corporation
  'ORA', // Ormat Technologies
  'PWR', // Plug Power
  'RNW', // Renew Energy Global PLC
  'RIVN', // Rivian Automotive, Inc.
  'SHLS', // Shoals Technologies Group
  'SEDG', // SolarEdge Technologies
  'NOVA', // Sunnova Energy International
  'RUN', // Sunrun
  'TMC', // TMC The Metals Company Inc.
  'TPIC', // TPI Composites
  'URG', // Ur-Energy
  'UEC', // Uranium Energy
  'XPEV', // XPeng
]);
let RH_SUPPORTED_TECH_STOCKS = new Set([
  'META',
  'CRM',
  'AAPL',
  'MSFT',
  'HOOD',
  'RBLX',
  'GOOG',
  'NVDA',
  'AI',
  'SAP',
  'AMD',
  'QCOM',
  'PANW',
  'EQIX',
  'NET',
  'FROG',
  'PD',
  'SNOW',
  'TEAM',
  'DOCN',
  'ZS',
  'OKTA',
  'SQ',
  'INFY',
  'CDNS',
  'SNPS',
  'AMAT',
  'ADBE',
  'MARA',
  'RIOT',
  'MSTR',
  'VGT',
  'XLK',
]);

let RH_SUPPORTED_CRYPTO = new Set([
  'ETH',
  'BTC',
  'DOGE',
  'AVAX',
  'ETC',
  'XLM',
  'BCH',
  'LINK',
  'UNI',
  'LTC',
  'AAVE',
  'XTZ',
  'COMP',
  'SHIB',
]);
let RH_SUPPORTED_ETFS = new Set([
  'SPY',
  'VOO',
  'IVV',
  'VTI',
  'QQQ',
  'VUG',
  'VEA',
  'VTV',
  'IEFA',
  'AGG',
  'BND',
  'IWF',
  'IJH',
  'VIG',
  'IJR',
  'VWO',
  'IEMG',
  'GLD',
  'VGT',
  'VXUS',
  'VO',
  'XLK',
  'IWM',
  'RSP',
  'SCHD',
]);
let RH_SUPPORTED_STOCKS = new Set([
  'VFS',
  'SMCI',
  'IAC',
  'NOW',
  'AAL',
  'HD',
  'WDAY',
  'DAL',
  'CHWY',
  'META',
  'NFLX',
  'DAL',
  'NIO',
  'CRM',
  'AAPL',
  'MSFT',
  'HOOD',
  'RBLX',
  'RIVN',
  'KOSS',
  'GME',
  'XOM',
  'HLX',
  'TSM',
  'NVDA',
  'AI',
  'SAP',
  'AMD',
  'QCOM',
  'PANW',
  'EQIX',
  'GOOG',
  'COP',
  'UUUU',
  'NET',
  'MANH',
  'FROG',
  'PD',
  'FTHM',
  'WMT',
  'LLY',
  'CRSP',
  'AUST',
  'GXO',
  'AIT',
  'MU',
  'SNOW',
  'TEAM',
  'DOCN',
  'ZS',
  'OKTA',
  'SQ',
  'TALO',
  'INFY',
  'CDNS',
  'SNPS',
  'AMX',
  'AMAT',
  'CEG',
  'PLUG',
  'LIN',
  'BLDP',
  'IREN',
  'ADBE',
  'CLSK',
  'MARA',
  'RIOT',
  'MSTR',
  'CLCO',
  'JKS',
  'SPY',
  'VOO',
  'IVV',
  'VTI',
  'QQQ',
  'VUG',
  'VEA',
  'VTV',
  'IEFA',
  'AGG',
  'BND',
  'IWF',
  'IJH',
  'VIG',
  'IJR',
  'VWO',
  'IEMG',
  'GLD',
  'VGT',
  'VXUS',
  'VO',
  'XLK',
  'IWM',
  'RSP',
  'SCHD',
  'NEP',
  'SMXT',
  'UMAC',
  'MOBX',
  'OPTT',
  'FLUX',
  'WAVE',
  'NNE',
  'UEC',
  'CCJ',
  'DDD',
  'PATH',
  'SYM',
  'PYPL',
  'GTLB',
  'PAC',
  'GAMB',
  'BBCP',
  'LAAC',
  'LAC',
  'APD',
  'HYLN',
  'HTOO',
  'HAL',
  'AAON',
  'AYI',
  'ADSE',
  'ADN',
  'AMPS',
  'AMRC',
  'APOG',
  'AQMS',
  'ACHR',
  'ARRY',
  'AY',
  'RERE',
  'BECN',
  'BYND',
  'BLNK',
  'BWEN',
  'BEPC',
  'CSIQ',
  'GOEV',
  'CARR',
  'CBAT',
  'CENN',
  'LEU',
  'CHPT',
  'CWEN',
  'FIX',
  'CMC',
  'CRKN',
  'CSWI',
  'DNMR',
  'DQ',
  'DNN',
  'ETN',
  'NRGV',
  'ENPH',
  'EOSE',
  'GWH',
  'EVGO',
  'EZGO',
  'FSLR',
  'FLNC',
  'FLUX',
  'FRGT',
  'FREY',
  'FTCI',
  'FF',
  'GATO',
  'GFL',
  'HDSN',
  'HYZN',
  'RBA',
  'IBP',
  'IONR',
  'IPX',
  'ITRI',
  'JZXN',
  'JOBY',
  'JCI',
  'KNDI',
  'KULR',
  'LSF',
  'LII',
  'LI',
  'LICY',
  'LTBR',
  'LKQ',
  'LOOP',
  'LYTS',
  'MAXN',
  'MVST',
  'MNTK',
  'MP',
  'MULN',
  'NXE',
  'NKLA',
  'NIU',
  'NUE',
  'SMR',
  'OTLY',
  'NVVE',
  'ORGN',
  'OESX',
  'ORA',
  'PESI',
  'PLPC',
  'PCT',
  'NX',
  'PWR',
  'QRHC',
  'RNW',
  'REFR',
  'RETO',
  'SPTN',
  'LEDS',
  'SHLS',
  'SEDG',
  'SLDP',
  'SCCO',
  'SPRU',
  'STKH',
  'STLD',
  'NOVA',
  'STKL',
  'RUN',
  'SWVL',
  'TGLS',
  'HAIN',
  'TMC',
  'BLD',
  'TPIC',
  'URG',
  'VMI',
  'WCN',
  'WM',
  'WTS',
  'WWR',
  'WLDN',
  'XOS',
  'XPEV',
]);
let BINANCE_SUPPORTED_CRYPTO = new Set([
  'DOGE',
  'ETH',
  'BTC',
  'LINK',
  'AVAX',
  'POL',
  'SOL',
  'DOT',
]);

let BITSO_USD_SUPPORTED_CRYPTO = new Set([
  'ETH',
  'BTC',
  'DOGE',
  'AVAX',
  'XLM',
  'BCH',
  'LINK',
  'UNI',
  'LTC',
  'AAVE',
  'COMP',
  'POL',
  'ADA',
  'SOL',
  'XRP',
  'DOT',
]);

// Function to sort a subset of the relevancy list (given as a Set)
function sortFinancialEntitiesByRelevancySet(subset) {
  // Convert the Set to an array to sort it
  const subsetArray = Array.from(subset);

  // Create a map of the relevancy index for each asset
  const relevancyIndex = RELEVANCY_RANKING.reduce((acc, item, index) => {
    acc[item] = index;
    return acc;
  }, {});

  // Sort the array based on its relevancy in the original relevancy list
  const sortedArray = subsetArray.sort((a, b) => {
    return relevancyIndex[a] - relevancyIndex[b];
  });

  // Return the sorted array as a Set (optional)
  return new Set(sortedArray);
}

function getInvestmentEntityType(entity) {
  if (RH_SUPPORTED_STOCKS.has(entity)) {
    return 'Stock';
  }
  return 'Crypto';
}

function getCryptosFromMarket(markets) {
  if (markets === 'robinhood') {
    return Array.from(RH_SUPPORTED_CRYPTO);
  }
  if (markets === 'bitso') {
    return Array.from(BITSO_USD_SUPPORTED_CRYPTO);
  }
  if (markets === 'binance') {
    return Array.from(BINANCE_SUPPORTED_CRYPTO);
  }
  return CRYPTO_CONFIG.names;
}
function getCryptosFromMarketList(markets) {
  let cryptos = new Set([]);

  if (markets.includes('robinhood_stocks')) {
    cryptos = new Set([...cryptos, ...RH_SUPPORTED_STOCKS]);
  }
  if (markets.includes('robinhood_crypto')) {
    cryptos = new Set([...cryptos, ...RH_SUPPORTED_CRYPTO]);
  }

  if (markets.includes('bitso')) {
    cryptos = new Set([...cryptos, ...BITSO_USD_SUPPORTED_CRYPTO]);
  }
  if (markets.includes('binance')) {
    cryptos = new Set([...cryptos, ...BINANCE_SUPPORTED_CRYPTO]);
  }
  cryptos = sortFinancialEntitiesByRelevancySet(cryptos);
  return Array.from(cryptos);
}
function getCryptosFromMarketListWithFilter(markets, filter) {
  let cryptos = new Set([]);
  if (filter === 'mining') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_MINING_STOCKS].filter(
          (x) => !RH_SUPPORTED_ETFS.has(x),
        ),
      );
    }
  }

  if (filter === 'climate_change') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_CLIMATE_CHANGE_STOCKS].filter(
          (x) => !RH_SUPPORTED_ETFS.has(x),
        ),
      );
    }
  }

  if (filter === 'oil') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_OIL_STOCKS].filter((x) => !RH_SUPPORTED_ETFS.has(x)),
      );
    }
  }
  if (filter === 'renewables') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_RENEWABLES_STOCKS].filter(
          (x) => !RH_SUPPORTED_ETFS.has(x),
        ),
      );
    }
  }
  if (filter === 'airlines') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_AIRLINE_STOCKS].filter(
          (x) => !RH_SUPPORTED_ETFS.has(x),
        ),
      );
    }
  }
  if (filter === 'logistics') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_LOGISTICS_STOCKS].filter(
          (x) => !RH_SUPPORTED_ETFS.has(x),
        ),
      );
    }
  }
  if (filter === 'tech') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_TECH_STOCKS].filter((x) => !RH_SUPPORTED_ETFS.has(x)),
      );
    }
  }

  if (filter === 'stocks') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(
        [...RH_SUPPORTED_STOCKS].filter((x) => !RH_SUPPORTED_ETFS.has(x)),
      );
    }
  }
  if (filter === 'etfs') {
    if (markets.includes('robinhood_stocks')) {
      cryptos = new Set(RH_SUPPORTED_ETFS);
    }
  }

  if (filter === 'crypto') {
    if (markets.includes('robinhood_crypto')) {
      cryptos = new Set([...cryptos, ...RH_SUPPORTED_CRYPTO]);
    }

    if (markets.includes('bitso')) {
      cryptos = new Set([...cryptos, ...BITSO_USD_SUPPORTED_CRYPTO]);
    }
    if (markets.includes('binance')) {
      cryptos = new Set([...cryptos, ...BINANCE_SUPPORTED_CRYPTO]);
    }
  }
  cryptos = sortFinancialEntitiesByRelevancySet(cryptos);
  return Array.from(cryptos);
}
function getPossibleRobinhoodMarkets(market_list) {
  const resp = [];
  if (market_list !== undefined && market_list !== null) {
    if (market_list.includes('robinhood_stocks')) {
      resp.push('robinhood_stocks');
    }
    if (market_list.includes('robinhood_crypto')) {
      resp.push('robinhood_crypto');
    }
  }
  return resp;
}

const possible_suffixes = [''];
function encryptString(input) {
  var derived_key = CryptoJS.enc.Base64.parse(ENCRYPT_KEY);
  var iv = CryptoJS.enc.Utf8.parse(ENCRYPT_KEY_IV);

  var encryptedAES = CryptoJS.AES.encrypt(input, derived_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return encryptedAES.toString();
}
let current_suffix = '';
function getBaseURL() {
  let randomElement =
    possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];

  if (current_suffix === randomElement) {
    randomElement =
      possible_suffixes[Math.floor(Math.random() * possible_suffixes.length)];
  }
  current_suffix = randomElement;

  return BASE_URL + current_suffix;
}

function pythonBackendURL() {
  return BASE_URL + '/crypto_bot_http_backend';
  //return 'http://127.0.0.1:5000';
}

async function getExchangePrices() {
  try {
    const response = await axios.get(`${getBaseURL()}/get_exchange_prices`);
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function deleteUser(user) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(`${pythonBackendURL()}/delete_user`, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

////generate_robinhood_key
async function generateRobinhoodKey(user) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(
      `${pythonBackendURL()}/generate_robinhood_key`,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getBuyingPower(user, market) {
  const tokenId = await user.getIdToken();

  try {
    const response = await axios.get(
      `${pythonBackendURL()}/get_buying_power/${market}`,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function editPlay(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_play/${data.id}`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function deleteFund(user, fund_id) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/delete_funds/${fund_id}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getCharts(time_period, crypto, currency) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_charts`, {
      params: {
        timeperiod: time_period,
        crypto: crypto,
        currency: currency,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getFunds2(user, market_app, asset, fundState, investmentFocus) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/get_funds2/${market_app}/${asset}/${fundState}/${investmentFocus}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function playFeed(user, crypto, playType, market) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_play_feed`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        crypto: crypto,
        playtype: playType,
        market: market,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getAppResultsFeed(timePeriod) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_app_results_feed`, {
      params: {
        crypto: 'ALL',
        playtype: 'ALL',
        market: 'ALL',
        timeperiod: timePeriod,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getOpenFundCounts(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_open_fund_counts`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function reuseFund(user, fundid) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/reuse_fund`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        fundid: fundid,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getPlay(playId) {
  try {
    const response = await axios.get(`${getBaseURL()}/get_play`, {
      params: {
        playid: playId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getTotalGainsForTimePeriod(user, timePeriod) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_total_gains`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: {
        timeperiod: timePeriod,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function getTotalGainsForAllTimePeriod(timePeriod) {
  try {
    const response = await axios.get(
      `${getBaseURL()}/get_total_gains_for_all`,
      {
        params: {
          timeperiod: timePeriod,
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getGlobalBetSettings(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_bet_settings`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
//get_market_apps

async function getMarketApps(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_market_apps`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function acceptTerms(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/accept_terms`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getCryptoInvestSetttings(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${getBaseURL()}/get_crypto_invest_settings`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postCryptoInvestSettingsList(
  user,
  cryptos_to_invest,
  cryptos_to_not_invest,
) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      cryptos_to_invest: cryptos_to_invest,
      cryptos_to_not_invest: cryptos_to_not_invest,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_crypto_invest_setttings2`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postCryptoInvestSettings(user, crypto, invest_mode) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      cryptos: [crypto],
      invest_mode: invest_mode,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_crypto_invest_setttings`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postFunds(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    console.log('post funds ');
    console.log(json);
    console.log('post funds ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_funds`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
const getPossibleBrokers = (market_list) => {
  const resp = [];
  if (market_list.includes('robinhood')) {
    resp.push('robinhood');
  }
  if (market_list.includes('bitso')) {
    resp.push('bitso');
  }
  if (market_list.includes('binance')) {
    resp.push('binance');
  }
  return resp;
};

async function getCryptoDetails(user, crypto, timeperiod) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_crypto_details`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
      params: { crypto: crypto, timeperiod: timeperiod },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getLapsedFunds(user) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(`${getBaseURL()}/get_lapsed_funds`, {
      headers: {
        'google-token-id': tokenId,
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getFunds(user, market_app, asset) {
  try {
    const tokenId = await user.getIdToken();

    const response = await axios.get(
      `${pythonBackendURL()}/get_funds/${market_app}/${asset}`,
      {
        headers: {
          'google-token-id': tokenId,
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

//post_pattern_day_trading_override
async function postDayTradingOverride(user, bet_setting) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      override: bet_setting,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_pattern_day_trading_override`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}
async function postTradingBotOperationDaySetting(user, ticker, bet_setting) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      ticker: ticker,
      operation: bet_setting,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_trading_bot_operation_day_setting`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function postPlaySettings(user, bet_setting) {
  try {
    const tokenId = await user.getIdToken();

    const data = {
      bet_setting: bet_setting,
    };

    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(
      `${pythonBackendURL()}/post_bet_settings`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function makePlay(user, data) {
  const tokenId = await user.getIdToken();

  try {
    const json = JSON.stringify(data);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(`${pythonBackendURL()}/make_play`, json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}
//post_user
async function postUser(user, data) {
  const tokenId = await user.getIdToken();
  try {
    const json = JSON.stringify(data);
    const response = await axios.post(`${pythonBackendURL()}/post_user`, json, {
      headers: {
        'google-token-id': tokenId,
        'Content-Type': 'text/plain',
        app: 'mobile',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

///

async function getNews(crypto_name) {
  try {
    const response = await axios.get(
      `https://cryptonews-api.com/api/v1?tickers=${crypto_name}&items=10&page=1&token=11pvsamc4gyxa2knydmg2kejcfikkwq0dwkls2na`,
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function robinhoodSignIn(user, data) {
  try {
    const tokenId = await user.getIdToken();
    data['robinhood_email'] = encryptString(data['robinhood_email']);
    data['robinhood_pw'] = encryptString(data['robinhood_pw']);

    const json = JSON.stringify(data);
    const response = await axios.post(
      `${pythonBackendURL()}/robinhood_sign_in`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function robinhoodTwoFa(user, data) {
  try {
    const tokenId = await user.getIdToken();

    const json = JSON.stringify(data);
    const response = await axios.post(
      `${pythonBackendURL()}/robinhood_two_fa`,
      json,
      {
        headers: {
          'google-token-id': tokenId,
          'Content-Type': 'text/plain',
          app: 'mobile',
        },
      },
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

function hideEmail(email) {
  const words = email.split('@');
  const hiddenEmail = words[0].substring(0, 1) + '*****';
  return hiddenEmail + '@' + words[1];
}

export const dashboardAPI = {
  CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION,
  CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION,
  CRYPTO_CONFIG,
  playFeed,
  getPlay,
  getCryptoInvestSetttings,
  getGlobalBetSettings,
  getMarketApps,
  postFunds,
  getFunds2,
  postCryptoInvestSettings,
  postPlaySettings,
  getTotalGainsForTimePeriod,
  getTotalGainsForAllTimePeriod,
  getOpenFundCounts,
  deleteFund,
  getCharts,
  editPlay,
  getExchangePrices,
  getFunds,
  makePlay,
  robinhoodTwoFa,
  robinhoodSignIn,
  postUser,
  getNews,
  getCryptoDetails,
  hideEmail,
  postCryptoInvestSettingsList,
  getLapsedFunds,
  deleteUser,
  getBuyingPower,
  acceptTerms,
  getAppResultsFeed,
  generateRobinhoodKey,
  encryptString,
  getCryptosFromMarketList,
  getCryptosFromMarket,
  getCryptosFromMarketListWithFilter,
  cryptoIconMap,
  cryptoSmallIconMap,
  reuseFund,
  investSettingsExplanations,
  RH_SUPPORTED_STOCKS,
  getPossibleBrokers,
  getPossibleRobinhoodMarkets,
  postDayTradingOverride,
  getInvestmentEntityType,
  postTradingBotOperationDaySetting,
};
