import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonText,
  Center,
  Heading,
  HStack,
  Pressable,
  Spinner,
  Text,
} from '@gluestack-ui/themed';
import { ScrollView, View } from 'react-native';
import { dashboardAPI as DashboardAPI, dashboardAPI } from '../../DashboardAPI';
import CryptoAvatar from './CryptoAvatar';
import { AuthContext } from '../../AuthProvider';
import { useNavigation } from '@react-navigation/native';

export default function SelectCryptos(params) {
  const { user, refreshMarketApps, marketApps } = useContext(AuthContext);

  useEffect(() => {}, [cryptoList]);

  const navigation = useNavigation();
  const allCryptos = {};
  const cryptos = dashboardAPI.getCryptosFromMarketList(marketApps);
  for (let i = 0; i < cryptos.length; i = i + 1) {
    if (params.settings !== undefined) {
      if (params.settings[cryptos[i]] === 'dont_invest') {
        allCryptos[cryptos[i]] = false;
      } else {
        allCryptos[cryptos[i]] = true;
      }
    } else {
      allCryptos[cryptos[i]] = false;
    }
  }
  const [cryptoSelect, setCryptoSelect] = React.useState(allCryptos);
  const [cryptoList, setCryptoList] = React.useState(cryptos);
  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState('all');

  const handleCryptoSelection = (asset2) => {
    let select = { ...cryptoSelect };

    select[asset2] = !cryptoSelect[asset2];
    setCryptoSelect(select);
  };

  const isAnyCryptoSelect = () => {
    for (let i = 0; i < cryptos.length; i = i + 1) {
      if (cryptoSelect[cryptos[i]]) {
        return false;
      }
    }
    return true;
  };

  const updateSelectionList = (filter2) => {
    let cryptos2 = null;
    let allCryptos2 = {};
    if (filter2 === 'all') {
      cryptos2 = dashboardAPI.getCryptosFromMarketList(marketApps);
    } else {
      cryptos2 = dashboardAPI.getCryptosFromMarketListWithFilter(
        marketApps,
        filter2,
      );
    }
    setCryptoList(cryptos2);
    setFilter(filter2);
  };

  const selectAll = () => {
    let select = { ...cryptoSelect };

    for (let i = 0; i < cryptoList.length; i++) {
      select[cryptoList[i]] = true;
    }
    setCryptoSelect(select);
  };

  const submitCryptoSelection = () => {
    const cryptos_to_invest = [];
    const cryptos_to_not_invest = [];
    for (let i = 0; i < dashboardAPI.CRYPTO_CONFIG.names.length; i = i + 1) {
      if (
        cryptos.includes(dashboardAPI.CRYPTO_CONFIG.names[i]) &&
        cryptoSelect[dashboardAPI.CRYPTO_CONFIG.names[i]]
      ) {
        cryptos_to_invest.push(dashboardAPI.CRYPTO_CONFIG.names[i]);
      } else {
        cryptos_to_not_invest.push(dashboardAPI.CRYPTO_CONFIG.names[i]);
      }
    }

    dashboardAPI
      .postCryptoInvestSettingsList(
        user,
        cryptos_to_invest,
        cryptos_to_not_invest,
      )
      .then((x) => {
        navigation.navigate('AddFunds');
        refreshMarketApps(user);
      });
  };

  return (
    <>
      {params.settings === undefined && (
        <Center>
          <Heading>CryptoPilot.app</Heading>
        </Center>
      )}
      {marketApps.includes('robinhood_stocks') ? (
        <Heading>Select the Cryptos/Stocks/ETFs you want to invest in</Heading>
      ) : (
        <Heading>Select the Cryptos you want to invest in</Heading>
      )}
      {marketApps.includes('robinhood_stocks') && (
        <>
          <View
            styles={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <ScrollView
              horizontal={true}
              contentContainerStyle={{
                paddingHorizontal: 20,
                paddingBottom: 12,
                paddingTop: 10,
              }}
            >
              <Heading mr={10}>Filters</Heading>
              <HStack space={'sm'}>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('all')}
                  variant={filter === 'all' ? 'solid' : 'outline'}
                >
                  <ButtonText>ALL</ButtonText>
                </Button>

                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('crypto')}
                  variant={filter === 'crypto' ? 'solid' : 'outline'}
                >
                  <ButtonText>Crypto</ButtonText>
                </Button>

                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('stocks')}
                  variant={filter === 'stocks' ? 'solid' : 'outline'}
                >
                  <ButtonText>Stocks</ButtonText>
                </Button>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('etfs')}
                  variant={filter === 'etfs' ? 'solid' : 'outline'}
                >
                  <ButtonText>ETFs</ButtonText>
                </Button>

                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('tech')}
                  variant={filter === 'tech' ? 'solid' : 'outline'}
                >
                  <ButtonText>Tech</ButtonText>
                </Button>

                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('climate_change')}
                  variant={filter === 'climate_change' ? 'solid' : 'outline'}
                >
                  <ButtonText>Climate Change</ButtonText>
                </Button>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('renewables')}
                  variant={filter === 'renewables' ? 'solid' : 'outline'}
                >
                  <ButtonText>Clean Energy</ButtonText>
                </Button>

                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('airlines')}
                  variant={filter === 'airlines' ? 'solid' : 'outline'}
                >
                  <ButtonText>Airlines</ButtonText>
                </Button>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('mining')}
                  variant={filter === 'mining' ? 'solid' : 'outline'}
                >
                  <ButtonText>Mining</ButtonText>
                </Button>
                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('logistics')}
                  variant={filter === 'logistics' ? 'solid' : 'outline'}
                >
                  <ButtonText>Logistics</ButtonText>
                </Button>

                <Button
                  size={'lg'}
                  borderRadius="$full"
                  onPress={() => updateSelectionList('oil')}
                  variant={filter === 'oil' ? 'solid' : 'outline'}
                >
                  <ButtonText>Oil</ButtonText>
                </Button>
              </HStack>
            </ScrollView>
          </View>
          {!isAnyCryptoSelect() && (
            <Button onPress={() => submitCryptoSelection()} m={10}>
              <ButtonText>Save</ButtonText>
            </Button>
          )}
        </>
      )}
      <Button onPress={() => selectAll()} m={10}>
        <ButtonText>Select All Below</ButtonText>
      </Button>

      {!loading ? (
        <ScrollView width={'100%'}>
          {cryptoList.map((crypto) => (
            <Pressable
              key={crypto}
              width={'98%'}
              onPress={() => handleCryptoSelection(crypto)}
            >
              <HStack
                mt={10}
                borderColor={
                  cryptoSelect[crypto] ? '$secondary600' : '$grey900'
                }
                backgroundColor={
                  cryptoSelect[crypto] ? '$secondary500' : '#fff'
                }
                borderWidth={3}
                borderRadius={'$full'}
              >
                <CryptoAvatar crypto={crypto} />
                <Heading color={cryptoSelect[crypto] ? 'white' : 'black'}>
                  {crypto} - {DashboardAPI.CRYPTO_CONFIG.fullNames[crypto]}
                </Heading>
              </HStack>
            </Pressable>
          ))}
        </ScrollView>
      ) : (
        <Loading />
      )}
      {isAnyCryptoSelect() && <Text>Select One Item</Text>}
      <Button
        isDisabled={isAnyCryptoSelect()}
        onPress={() => submitCryptoSelection()}
        m={10}
      >
        <ButtonText>Save</ButtonText>
      </Button>
    </>
  );
}
