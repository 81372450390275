import React, { useContext, useEffect } from 'react';
import {
  Badge,
  BadgeText,
  Box,
  Button,
  ButtonText,
  Center,
  HStack,
  Heading,
  Input,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  ActionsheetItemText,
  Actionsheet,
  InputField,
  ActionsheetItem,
  Divider,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@gluestack-ui/themed';

import { dashboardAPI } from '../DashboardAPI';

import { AuthContext } from '../AuthProvider';
import CryptoAvatar from './shared/CryptoAvatar';
import { FlatList, ScrollView, StyleSheet, View } from 'react-native';
import Loading from './shared/Loading';
import Fund from './shared/Fund';

export default function ManageFunds({ navigation, route }) {
  const { user, marketApps } = useContext(AuthContext);

  const [crypto, setCrypto] = React.useState('ALL');
  const [investmentFocus, setInvesmentFocus] = React.useState('ALL');
  const [fundType, setFundType] = React.useState('ALL');
  const [fundState, setFundState] = React.useState('ALL');
  const [marketApp, setMarketApp] = React.useState('ALL');
  const [isLoading, setIsLoading] = React.useState(false);
  const [cryptos, setCryptos] = React.useState(
    dashboardAPI.getCryptosFromMarketList(marketApps),
  );

  const [funds, setFunds] = React.useState([]);

  const [showCryptoSelectionSheet, setShowCryptoSelectionSheet] =
    React.useState(false);
  const [showCryptoFocusSelectionSheet, setShowCryptoFocusSelectionSheet] =
    React.useState(false);
  const [showFundTypeSelectionSheet, setShowFundTypeSelectionSheet] =
    React.useState(false);
  const [showFundStateSelectionSheet, setShowFundStateSelectionSheet] =
    React.useState(false);
  const [showMarketSelectionSheet, setShowMarketSelectionSheet] =
    React.useState(false);

  async function loadFunds(marketApp2, crypto2, fundState2, investmentFocus) {
    setIsLoading(true);
    dashboardAPI
      .getFunds2(user, marketApp2, crypto2, fundState2, investmentFocus)
      .then((x) => {
        setFunds(x.data);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    (async () => {
      loadFunds(marketApp, crypto, fundState, investmentFocus);
    })();
  }, []);

  const handleCloseCryptoSelection = (asset2) => {
    setCrypto(asset2);
    setShowCryptoSelectionSheet(false);
    loadFunds(marketApp, asset2, fundState, investmentFocus);
  };
  const handleCloseCryptoFocusSelection = (asset2) => {
    setInvesmentFocus(asset2);
    setShowCryptoFocusSelectionSheet(false);
    loadFunds(marketApp, crypto, fundState, asset2);
  };

  const handleCloseFundTypeSelection = (asset2) => {
    setFundType(asset2);
    setShowFundTypeSelectionSheet(false);
    loadFunds();
  };

  const handleCloseFundStateSelection = (asset2) => {
    setFundState(asset2);
    setShowFundStateSelectionSheet(false);
    loadFunds(marketApp, crypto, asset2, investmentFocus);
  };

  const handleCloseMarketSelection = (asset2) => {
    setMarketApp(asset2);
    setShowMarketSelectionSheet(false);
    loadFunds(asset2, crypto, fundState, investmentFocus);
  };

  const setAmountInUsdAndUpdateTotalInvestment = (newAmountInUsd) => {
    if (isNaN(Number(amountInUsd))) {
      return;
    }
    setAmountInUsd(newAmountInUsd);
    setAmountInUsdInt(parseInt(newAmountInUsd));
    if (marketApp === 'robinhood') {
      setQuantityOfFunds(parseInt(newAmountInUsd));
      setFundAmount('1.00');
    } else {
      setQuantityOfFunds(parseInt(parseInt(newAmountInUsd) / 20.0));
      setFundAmount('20.00');
    }
  };
  const setQuantityOfFundsAndUpdateTotalInvestment = (newQuantity) => {
    setQuantityOfFunds(newQuantity);

    setFundAmount((parseFloat(amountInUsd) / newQuantity).toFixed(2));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    console.log('in the onsubmit');

    const value = {
      // user: profile.email,
      status: 'open',
      asset: asset,
      amount_in_fiat_currency: fundAmount,
      number_of_funds_to_make: quantityOfFunds,
      market_app: marketApp,
      currency: marketApp === 'robinhood' ? 'usd' : 'mxn',
      fund_expiring_setting: fundExpiringSetting,
    };
    if (
      typeof value.amount_in_fiat_currency === 'string' &&
      !value.amount_in_fiat_currency.endsWith('.00')
    ) {
      value.amount_in_fiat_currency = (
        Math.round(parseFloat(value.amount_in_fiat_currency) * 100) / 100
      ).toFixed(2);
    }
    console.log('data for new fund');
    console.log(value);
    console.log(typeof value.amount_in_fiat_currency);
    console.log('data for new fund');
    dashboardAPI.postFunds(user, value).then((x) => {
      navigation.navigate('Home');
    });
  };

  const statusText = {
    ALL: 'Any Fund',
    in_use: 'Funds Occupied On Play ',
    open: 'Open Funds For Plays',
  };
  const typeText = {
    ALL: 'Any Fund',
    BULL: 'Funds For BULL Plays',
    BEAR: 'Funds For BEAR Plays',
  };

  return (
    <>
      <Heading>Filters</Heading>
      <View
        styles={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <ScrollView
          horizontal={true}
          contentContainerStyle={{ paddingHorizontal: 40 }}
        >
          <Center ml={5} mr={5}>
            <Text>Status</Text>
            <Button
              w={fundState === 'ALL' ? '200px' : '300px'}
              onPress={() => setShowFundStateSelectionSheet(true)}
              variant={'outline'}
              action={'primary'}
            >
              <ButtonText>{statusText[fundState]}</ButtonText>
            </Button>
          </Center>
          {/*<Center>*/}
          {/*  <Text>Fund Type</Text>*/}
          {/*  <Button w={ fundType === 'ALL' ? '200px' : '300px'} onPress={() => setShowFundTypeSelectionSheet(true)} variant={'outline'} action={'primary'}   ><ButtonText>{typeText[fundType]}</ButtonText></Button>*/}
          {/*</Center>*/}
          {/*<Text> </Text>*/}
          <Center ml={5} mr={5}>
            <Text>Asset</Text>
            <Button
              w={'200px'}
              variant={'outline'}
              action={'primary'}
              onPress={() => setShowCryptoSelectionSheet(true)}
            >
              {['ALL', 'cash'].includes(crypto) ? (
                <></>
              ) : (
                <CryptoAvatar size={'sm'} crypto={crypto} />
              )}
              <ButtonText>
                {crypto === 'ALL' ? 'All Cryptos' : crypto}
              </ButtonText>
            </Button>
          </Center>

          {marketApps.length > 1 && (
            <Center ml={5} mr={5}>
              <Text>Market</Text>
              <Button
                w={'200px'}
                variant={'outline'}
                action={'primary'}
                onPress={() => setShowMarketSelectionSheet(true)}
              >
                <ButtonText>{marketApp}</ButtonText>
              </Button>
            </Center>
          )}
          {['cash', 'ALL'].includes(crypto) && (
            <Center ml={5} mr={5}>
              <Text>Investment Purpose</Text>
              <Button
                w={'200px'}
                variant={'outline'}
                action={'primary'}
                onPress={() => setShowCryptoFocusSelectionSheet(true)}
              >
                {['ALL', 'cash'].includes(investmentFocus) ? (
                  <></>
                ) : (
                  <CryptoAvatar size={'sm'} crypto={investmentFocus} />
                )}
                <ButtonText>
                  {investmentFocus === 'ALL' ? 'All Cryptos' : investmentFocus}
                </ButtonText>
              </Button>
            </Center>
          )}
        </ScrollView>
      </View>

      {funds.length > 0 && !isLoading ? (
        <FlatList
          w={'$95'}
          h={'$90'}
          data={funds}
          renderItem={({ x, index }) => {
            return <Fund key={funds[index]['id']} fund={funds[index]} />;
          }}
          keyExtractor={(x, index) => funds[index]['id']}
        />
      ) : (
        <Center>
          <Loading />
        </Center>
      )}

      <Actionsheet
        isOpen={showCryptoFocusSelectionSheet}
        onClose={() =>
          setShowCryptoFocusSelectionSheet(!showCryptoFocusSelectionSheet)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Focused Investment
          </ActionsheetSectionHeaderText>

          <View style={{ height: '80%', width: '95%' }}>
            <ScrollView>
              <ActionsheetItem
                onPress={() => handleCloseCryptoFocusSelection('ALL')}
              >
                <ActionsheetItemText>All Cryptos</ActionsheetItemText>
              </ActionsheetItem>
              {cryptos.includes('BTC') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('BTC')}
                >
                  <CryptoAvatar crypto={'BTC'} />
                  <ActionsheetItemText>BTC</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('ETH') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('ETH')}
                >
                  <CryptoAvatar crypto={'ETH'} />
                  <ActionsheetItemText>ETH</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('DOGE') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('DOGE')}
                >
                  <CryptoAvatar crypto={'DOGE'} />
                  <ActionsheetItemText>DOGE</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('AVAX') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('AVAX')}
                >
                  <CryptoAvatar crypto={'AVAX'} />
                  <ActionsheetItemText>AVAX</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('LINK') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('LINK')}
                >
                  <CryptoAvatar crypto={'LINK'} />
                  <ActionsheetItemText>LINK</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('UNI') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('UNI')}
                >
                  <CryptoAvatar crypto={'UNI'} />
                  <ActionsheetItemText>UNI</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('XLM') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('XLM')}
                >
                  <CryptoAvatar crypto={'XLM'} />
                  <ActionsheetItemText>XLM</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('XTZ') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('XTZ')}
                >
                  <CryptoAvatar crypto={'XTZ'} />
                  <ActionsheetItemText>XTZ</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('COMP') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('COMP')}
                >
                  <CryptoAvatar crypto={'COMP'} />
                  <ActionsheetItemText>COMP</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('AAVE') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('AAVE')}
                >
                  <CryptoAvatar crypto={'AAVE'} />
                  <ActionsheetItemText>AAVE</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('LTC') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('LTC')}
                >
                  <CryptoAvatar crypto={'LTC'} />
                  <ActionsheetItemText>LTC</ActionsheetItemText>
                </ActionsheetItem>
              )}

              {cryptos.includes('ADA') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('ADA')}
                >
                  <CryptoAvatar crypto={'ADA'} />
                  <ActionsheetItemText>ADA</ActionsheetItemText>
                </ActionsheetItem>
              )}

              {cryptos.includes('SHIB') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('SHIB')}
                >
                  <CryptoAvatar crypto={'SHIB'} />
                  <ActionsheetItemText>SHIB</ActionsheetItemText>
                </ActionsheetItem>
              )}

              {cryptos.includes('POL') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('POL')}
                >
                  <CryptoAvatar crypto={'POL'} />
                  <ActionsheetItemText>POL</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('SOL') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('SOL')}
                >
                  <CryptoAvatar crypto={'SOL'} />
                  <ActionsheetItemText>SOL</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('XRP') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('XRP')}
                >
                  <CryptoAvatar crypto={'XRP'} />
                  <ActionsheetItemText>XRP</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('DOT') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoFocusSelection('DOT')}
                >
                  <CryptoAvatar crypto={'DOT'} />
                  <ActionsheetItemText>DOT</ActionsheetItemText>
                </ActionsheetItem>
              )}
            </ScrollView>
          </View>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showCryptoSelectionSheet}
        onClose={() => setShowCryptoSelectionSheet(!showCryptoSelectionSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Fund Asset Kinds
          </ActionsheetSectionHeaderText>

          <View style={{ height: '80%', width: '95%' }}>
            <ScrollView>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('ALL')}
              >
                <ActionsheetItemText>All Cryptos</ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('cash')}
              >
                <CryptoAvatar crypto={'cash'} />
                <ActionsheetItemText>Cash</ActionsheetItemText>
              </ActionsheetItem>

              {cryptos.includes('BTC') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('BTC')}
                >
                  <CryptoAvatar crypto={'BTC'} />
                  <ActionsheetItemText>BTC</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('ETH') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('ETH')}
                >
                  <CryptoAvatar crypto={'ETH'} />
                  <ActionsheetItemText>ETH</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('DOGE') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('DOGE')}
                >
                  <CryptoAvatar crypto={'DOGE'} />
                  <ActionsheetItemText>DOGE</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('AVAX') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('AVAX')}
                >
                  <CryptoAvatar crypto={'AVAX'} />
                  <ActionsheetItemText>AVAX</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('LINK') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('LINK')}
                >
                  <CryptoAvatar crypto={'LINK'} />
                  <ActionsheetItemText>LINK</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('UNI') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('UNI')}
                >
                  <CryptoAvatar crypto={'UNI'} />
                  <ActionsheetItemText>UNI</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('XLM') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('XLM')}
                >
                  <CryptoAvatar crypto={'XLM'} />
                  <ActionsheetItemText>XLM</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('XTZ') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('XTZ')}
                >
                  <CryptoAvatar crypto={'XTZ'} />
                  <ActionsheetItemText>XTZ</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('COMP') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('COMP')}
                >
                  <CryptoAvatar crypto={'COMP'} />
                  <ActionsheetItemText>COMP</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('AAVE') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('AAVE')}
                >
                  <CryptoAvatar crypto={'AAVE'} />
                  <ActionsheetItemText>AAVE</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('LTC') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('LTC')}
                >
                  <CryptoAvatar crypto={'LTC'} />
                  <ActionsheetItemText>LTC</ActionsheetItemText>
                </ActionsheetItem>
              )}

              {cryptos.includes('ADA') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('ADA')}
                >
                  <CryptoAvatar crypto={'ADA'} />
                  <ActionsheetItemText>ADA</ActionsheetItemText>
                </ActionsheetItem>
              )}

              {cryptos.includes('SHIB') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('SHIB')}
                >
                  <CryptoAvatar crypto={'SHIB'} />
                  <ActionsheetItemText>SHIB</ActionsheetItemText>
                </ActionsheetItem>
              )}

              {cryptos.includes('POL') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('POL')}
                >
                  <CryptoAvatar crypto={'POL'} />
                  <ActionsheetItemText>POL</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('SOL') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('SOL')}
                >
                  <CryptoAvatar crypto={'SOL'} />
                  <ActionsheetItemText>SOL</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('XRP') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('XRP')}
                >
                  <CryptoAvatar crypto={'XRP'} />
                  <ActionsheetItemText>XRP</ActionsheetItemText>
                </ActionsheetItem>
              )}
              {cryptos.includes('DOT') && (
                <ActionsheetItem
                  onPress={() => handleCloseCryptoSelection('DOT')}
                >
                  <CryptoAvatar crypto={'DOT'} />
                  <ActionsheetItemText>DOT</ActionsheetItemText>
                </ActionsheetItem>
              )}
            </ScrollView>
          </View>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showFundStateSelectionSheet}
        onClose={() =>
          setShowFundStateSelectionSheet(!showFundStateSelectionSheet)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>Fund Type</ActionsheetSectionHeaderText>
          <ActionsheetItem onPress={() => handleCloseFundStateSelection('ALL')}>
            <ActionsheetItemText>All Funds</ActionsheetItemText>
          </ActionsheetItem>
          <ActionsheetItem
            onPress={() => handleCloseFundStateSelection('open')}
          >
            <ActionsheetItemText>Funds Open For Plays</ActionsheetItemText>
          </ActionsheetItem>

          <ActionsheetItem
            onPress={() => handleCloseFundStateSelection('in_use')}
          >
            <ActionsheetItemText>Funds In Use</ActionsheetItemText>
          </ActionsheetItem>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showFundTypeSelectionSheet}
        onClose={() =>
          setShowFundTypeSelectionSheet(!showFundTypeSelectionSheet)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>Fund Type</ActionsheetSectionHeaderText>
          <ActionsheetItem onPress={() => handleCloseFundTypeSelection('ALL')}>
            <ActionsheetItemText>All Funds</ActionsheetItemText>
          </ActionsheetItem>
          <ActionsheetItem onPress={() => handleCloseFundTypeSelection('BULL')}>
            <ActionsheetItemText>Funds for Bull Plays</ActionsheetItemText>
          </ActionsheetItem>

          <ActionsheetItem onPress={() => handleCloseFundTypeSelection('BEAR')}>
            <ActionsheetItemText>Funds for Bear Plays</ActionsheetItemText>
          </ActionsheetItem>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showMarketSelectionSheet}
        onClose={() => setShowMarketSelectionSheet(!showMarketSelectionSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Crypto Market
          </ActionsheetSectionHeaderText>
          {marketApps.includes('robinhood') && (
            <ActionsheetItem
              onPress={() => handleCloseMarketSelection('robinhood')}
            >
              <ActionsheetItemText>Robinhood</ActionsheetItemText>
            </ActionsheetItem>
          )}

          {marketApps.includes('bitso') && (
            <ActionsheetItem
              onPress={() => handleCloseMarketSelection('bitso')}
            >
              <ActionsheetItemText>Bitso</ActionsheetItemText>
            </ActionsheetItem>
          )}

          {marketApps.includes('binance') && (
            <ActionsheetItem
              onPress={() => handleCloseMarketSelection('binance')}
            >
              <ActionsheetItemText>Binance</ActionsheetItemText>
            </ActionsheetItem>
          )}
        </ActionsheetContent>
      </Actionsheet>
    </>
  );
}
const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
