import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonText,
  Heading,
  VStack,
  Toast,
  ToastTitle,
  useToast,
  Center,
  Text,
  Box,
} from '@gluestack-ui/themed';

import { ScrollView, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native';
import SelectCryptos from './shared/SelectCryptos';
import { dashboardAPI } from '../DashboardAPI';
import { AuthContext } from '../AuthProvider';
import Loading from './shared/Loading';

export default function InvestmentSelectionScreen() {
  const { user } = useContext(AuthContext);
  const [cryptoSettings, setCryptoSettings] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    (async () => {
      dashboardAPI.getCryptoInvestSetttings(user).then((x) => {
        console.log(x.data);
        setCryptoSettings(x.data);
        setLoading(false);
      });
    })();
  }, []);

  return (
    <SafeAreaView style={{ flex: 8 }}>
      {loading ? <Loading /> : <SelectCryptos settings={cryptoSettings} />}
    </SafeAreaView>
  );
}
