import {
  Box,
  Button,
  ButtonText,
  Center,
  HStack,
  Text,
  Heading,
  VStack,
  Icon,
  ArrowDownIcon,
  ArrowUpIcon,
  Divider,
  Image,
  Avatar,
  ArrowLeftIcon,
  Pressable,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  RadioGroup,
  Radio,
  RadioIndicator,
  RadioIcon,
  CircleIcon,
  RadioLabel,
  Actionsheet,
} from '@gluestack-ui/themed';
import {
  Dimensions,
  FlatList,
  Linking,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  SafeAreaView,
  Platform,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useContext, useEffect } from 'react';
import CryptoAvatar from './shared/CryptoAvatar';
import { dashboardAPI } from '../DashboardAPI';
import { LineChart } from 'react-native-wagmi-charts';
import Loading from './shared/Loading';
import { AuthContext } from '../AuthProvider';
import Play from './shared/Play';
import { Header } from '@react-navigation/stack';

const cryptoMappedToColor = {
  XTZ: 'rgba(166, 224, 0, 0.6)',
  DOGE: 'rgba(194, 166, 51, 0.5)',
  BTC: 'rgba(251, 186, 23, 0.5)',
  ETC: 'rgba(11, 131, 17, 0.4)',
  ETH: 'rgba(78, 142, 233, 0.6)',
  BCH: 'rgba(62, 198, 62, 0.5)',
  XLM: '#5B6A72',
  COMP: 'rgba(0, 211, 149, 0.6)',
  LTC: '#BEBEBE',
  UNI: 'rgba(255, 0, 122, 0.4)',
  AVAX: 'rgba(232, 65, 66, 0.6)',
  AAVE: 'rgba(46, 186, 198, 0.6)',
  LINK: 'rgba(55, 91, 210, 0.6)',
};
const cryptoMappedToSecondaryColor = {
  XTZ: 'black',
  DOGE: '#124559',
  BTC: 'black',
  ETC: '#F93943',
  ETH: '#F93943',
  BCH: 'black',
  XLM: '#FF007A',
  COMP: '#F93943',
  LTC: '#FF007A',
  UNI: 'black',
  AVAX: 'black',
  AAVE: '#F93943',
  LINK: '#F93943',
};
const investmentModeTitles = {
  crypto: 'Investing In {crypto} For {StockOrCrypto} Gains',
  usd: 'Investing In {crypto} For Cash Gains',
  both: 'Investing In {crypto} Alternate Gains',
  dont_invest: 'Not Investing In {crypto}',
};

const CryptoDetails = ({ route, navigation }) => {
  const [chartData, setChartData] = React.useState(null);
  const [dateRange, setDateRange] = React.useState(
    route.params.dateRange ? route.params.dateRange : '3M',
  );
  const [news, setNews] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [marketOperation, setMarketOperation] = React.useState('');

  const [rankings, setRankings] = React.useState([]);
  const [recentPlays, setRecentPlays] = React.useState([]);
  const {
    user,
    exchangePrices,
    cryptoInvestSettings,
    refreshMarketApps,
    marketAppsLoading,
    patternDayTradingOverride,
    marketApps,
  } = useContext(AuthContext);
  const [totalGains, setTotalGains] = React.useState(null);
  const [allCommunityPlays, setAllCommunityPlays] = React.useState(null);
  const [allCommunityGains, setAllCommunityGains] = React.useState(null);

  const [currentTab, setCurrenTab] = React.useState('results');
  const [showInvestSettings, setShowInvestSettings] = React.useState(false);
  const [showMarketOperationSettings, setShowMarketOperationSettings] =
    React.useState(false);

  let width = Dimensions.get('window').width;
  let height = Dimensions.get('window').height;

  useEffect(() => {
    (async () => {
      handleTimePeriodSelect(dateRange, route.params.coinName);
      getNews(route.params.coinName);
      loadFunds(route.params.coinName, dateRange);
    })();
  }, [
    route.params.coinName,
    dateRange,
    user,
    cryptoInvestSettings,
    marketAppsLoading,
  ]);

  // useEffect(() => {
  //   if (dateRange !== route.params.dataRange) {
  //     setDateRange(route.params.dateRange);
  //   }
  // }, [route.params.dateRange]);

  function getNews(coinname) {
    dashboardAPI.getNews(coinname).then((x2) => {
      setNews(x2.data.data);
    });
  }

  async function loadFunds(crypto2, dateRange2) {
    setRecentPlays([]);
    const cutoff = new Date();
    if (dateRange2 === '1H') {
      const dateOffset = 60 * 60 * 1000;
      cutoff.setTime(cutoff.getTime() - dateOffset);
    } else if (dateRange2 === '1D') {
      const dateOffset = 24 * 60 * 60 * 1000;
      cutoff.setTime(cutoff.getTime() - dateOffset);
    } else if (dateRange2 === '1W') {
      const dateOffset = 24 * 7 * 60 * 60 * 1000;
      cutoff.setTime(cutoff.getTime() - dateOffset);
    } else if (dateRange2 === '1M') {
      const dateOffset = 24 * 30 * 60 * 60 * 1000;
      cutoff.setTime(cutoff.getTime() - dateOffset);
    } else if (dateRange2 === '3M') {
      const dateOffset = 24 * 90 * 60 * 60 * 1000;
      cutoff.setTime(cutoff.getTime() - dateOffset);
    }

    dashboardAPI.playFeed(user, crypto2, 'ALL', 'ALL').then((x) => {
      if (x.data.recent_plays !== null) {
        let newData = null;
        if (dateRange2 === 'ALL_TIME') {
          newData = x.data.recent_plays;
        } else {
          newData = x.data.recent_plays.filter(
            (play) => new Date(play.last_upd_ts) > cutoff,
          );
        }
        setRecentPlays(newData);
      }
    });
  }
  const setCryptoInvestSetting = (setting) => {
    setShowInvestSettings(false);
    setLoading(true);
    dashboardAPI
      .postCryptoInvestSettings(user, route.params.coinName, setting)
      .then((x) => {
        refreshMarketApps(user);
        setLoading(false);
      });
  };
  const setMarketOperationSetting = (setting) => {
    setShowMarketOperationSettings(false);
    setLoading(true);
    dashboardAPI
      .postTradingBotOperationDaySetting(user, route.params.coinName, setting)
      .then((x) => {
        refreshMarketApps(user);
        setLoading(false);
      });
  };

  function handleTimePeriodSelect(dateRangee) {
    setChartData(null);
    setTotalGains(null);
    setRankings([]);
    dashboardAPI.getCharts(dateRangee, route.params.coinName).then((x2) => {
      let newData = [];
      for (let i = 0; i < x2.data.candles.length; i++) {
        const obj = {
          timestamp: new Date(x2.data.candles[i].begins_at),
          value: x2.data.candles[i].close_price,
          price: x2.data.candles[i].close_price,
        };
        newData.push(obj);
      }
      setChartData(newData);
      setDateRange(dateRangee);
    });

    dashboardAPI.getTotalGainsForTimePeriod(user, dateRangee).then((x) => {
      setTotalGains(parseFloat(x.data.total_gains[route.params.coinName]));
    });

    dashboardAPI
      .getCryptoDetails(user, route.params.coinName, dateRangee)
      .then((x) => {
        console.log('getCryptoDetails response', x.data);
        setMarketOperation(x.data.trading_day_operation.operation);
        setAllCommunityPlays(parseFloat(x.data.total_closed_plays));
        if (x.data.rankings !== null) {
          for (let i = 0; i < x.data.rankings.length; i++) {
            if (x.data.rankings[i].user === 'ALL') {
              setAllCommunityGains(
                (
                  x.data.rankings[i].crypto_winnings *
                  exchangePrices[route.params.coinName]
                ).toFixed(2) + ' USD',
              );
            }
          }
          setRankings(x.data.rankings);
        }
      });
  }

  return (
    <SafeAreaView
      style={{ height: '100%', width: '100%', backgroundColor: 'white' }}
    >
      <ScrollView>
        <LinearGradient
          colors={[
            cryptoMappedToColor[route.params.coinName],
            cryptoMappedToColor[route.params.coinName],
            '#ffffff',
            '#ffffff',
            '#ffffff',
            '#ffffff',
            '#ffffff',
          ]}
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
          }}
        />
        <Pressable onPress={() => navigation.navigate('Home')}>
          <Box backgroundColor={'#fff'}>
            <HStack ml={10} mr={10} mt={3}>
              <Icon as={ArrowLeftIcon} size={'xl'} mt={20} />
              <CryptoAvatar size={'lg'} crypto={route.params.coinName} />
              <VStack>
                <Heading size={'lg'}>
                  {route.params.coinName} -{' '}
                  {dashboardAPI.CRYPTO_CONFIG.fullNames[route.params.coinName]}
                </Heading>
                {exchangePrices !== false && (
                  <Heading size={'sm'}>
                    $
                    {exchangePrices[route.params.coinName].toFixed(
                      dashboardAPI.CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                        route.params.coinName
                      ],
                    )}{' '}
                    USD
                  </Heading>
                )}
              </VStack>
            </HStack>

            {exchangePrices !== false &&
              chartData !== null &&
              chartData.length > 0 && (
                <>
                  {exchangePrices[route.params.coinName] >=
                  chartData[0].price ? (
                    <HStack ml={10} mr={10} mb={3}>
                      <Icon color={'$success500'} as={ArrowUpIcon} size="md" />
                      <Text color={'$success500'}>
                        $
                        {(
                          exchangePrices[route.params.coinName] -
                          chartData[0].price
                        ).toFixed(
                          dashboardAPI.CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                            route.params.coinName
                          ],
                        )}
                        (
                        {(
                          ((exchangePrices[route.params.coinName] -
                            chartData[0].price) /
                            chartData[0].price) *
                          100.0
                        ).toFixed(3)}
                        %) - {dateRange === 'ALL_TIME' ? '1Y' : dateRange}
                      </Text>
                    </HStack>
                  ) : (
                    <HStack ml={10} mr={10} mb={3}>
                      <Icon color={'$error500'} as={ArrowDownIcon} size="md" />
                      <Text color={'$error500'}>
                        $
                        {(
                          chartData[0].price -
                          exchangePrices[route.params.coinName]
                        ).toFixed(
                          dashboardAPI.CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                            route.params.coinName
                          ],
                        )}
                        (
                        {(
                          ((chartData[0].price -
                            exchangePrices[route.params.coinName]) /
                            chartData[0].price) *
                          100.0
                        ).toFixed(3)}
                        %) - {dateRange === 'ALL_TIME' ? '1Y' : dateRange}
                      </Text>
                    </HStack>
                  )}
                </>
              )}
          </Box>
        </Pressable>
        <Box m={10} backgroundColor={'#fff'}>
          <Button
            onPress={() => setShowInvestSettings(true)}
            variant={'outline'}
          >
            <ButtonText>
              {investmentModeTitles[cryptoInvestSettings[route.params.coinName]]
                .replace('{crypto}', route.params.coinName)
                .replace(
                  '{StockOrCrypto}',
                  dashboardAPI.getInvestmentEntityType(route.params.coinName),
                )}
            </ButtonText>
          </Button>
        </Box>
        {(!loading || !marketAppsLoading) &&
          marketApps.includes('robinhood_stocks') &&
          dashboardAPI.RH_SUPPORTED_STOCKS.has(route.params.coinName) &&
          !patternDayTradingOverride && (
            <Box m={10} backgroundColor={'#fff'}>
              <Button
                onPress={() => setShowMarketOperationSettings(true)}
                variant={'outline'}
              >
                <ButtonText>
                  {marketOperation === ''
                    ? 'BUY/SELL ' + route.params.coinName
                    : 'TODAY ONLY ' +
                      marketOperation.toUpperCase() +
                      ' {crypto}'.replace('{crypto}', route.params.coinName)}
                </ButtonText>
              </Button>
            </Box>
          )}
        <Center>
          {chartData !== null && (!loading || !marketAppsLoading) ? (
            <LineChart.Provider data={chartData}>
              <Center>
                <LineChart width={width * 0.97} height={height / 3}>
                  <LineChart.Path
                    color={cryptoMappedToSecondaryColor[route.params.coinName]}
                  >
                    <LineChart.HorizontalLine
                      at={{ index: 0 }}
                      color={
                        cryptoMappedToSecondaryColor[route.params.coinName]
                      }
                    />

                    <LineChart.Gradient
                      color={
                        cryptoMappedToSecondaryColor[route.params.coinName]
                      }
                      //color={cryptoMappedToColor[route.params.coinName]}
                      //color={'black'}
                    />
                  </LineChart.Path>
                </LineChart>
              </Center>
            </LineChart.Provider>
          ) : (
            <Center width={width * 0.97} height={height / 3}>
              <Loading />
            </Center>
          )}
          <HStack
            width={'100%'}
            style={styles.card}
            space={'4xl'}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onPress={() => handleTimePeriodSelect('1D')}
              variant={dateRange === '1D' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 1D </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('1W')}
              variant={dateRange === '1W' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 1W </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('1M')}
              variant={dateRange === '1M' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 1M </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('3M')}
              variant={dateRange === '3M' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText> 3M </ButtonText>
            </Button>
            <Button
              onPress={() => handleTimePeriodSelect('ALL_TIME')}
              variant={dateRange === 'ALL_TIME' ? 'solid' : 'link'}
              size={'xs'}
            >
              <ButtonText>ALL TIME</ButtonText>
            </Button>
          </HStack>
        </Center>

        <View
          mt={10}
          styles={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ScrollView
            horizontal={true}
            contentContainerStyle={{
              paddingHorizontal: 20,
              paddingBottom: 12,
              paddingTop: 10,
            }}
          >
            <HStack space={'sm'}>
              <Button
                size={'lg'}
                onPress={() => setCurrenTab('results')}
                borderRadius="$full"
                variant={currentTab === 'results' ? 'solid' : 'outline'}
                backgroundColor={
                  currentTab === 'results' ? '$primary500' : 'white'
                }
              >
                <ButtonText>Results</ButtonText>
              </Button>

              <Button
                size={'lg'}
                onPress={() => setCurrenTab('news')}
                borderRadius="$full"
                variant={currentTab === 'news' ? 'solid' : 'outline'}
                backgroundColor={
                  currentTab === 'news' ? '$primary500' : 'white'
                }
              >
                <ButtonText>News</ButtonText>
              </Button>

              <Button
                size={'lg'}
                onPress={() => setCurrenTab('community')}
                borderRadius="$full"
                variant={currentTab === 'community' ? 'solid' : 'outline'}
                backgroundColor={
                  currentTab === 'community' ? '$primary500' : 'white'
                }
              >
                <ButtonText>Community</ButtonText>
              </Button>
            </HStack>
          </ScrollView>
        </View>

        {currentTab === 'results' && (
          <Center mb={30}>
            <Box style={styles.card}>
              <Heading size={'lg'}>Gains</Heading>
              {totalGains !== null ? (
                exchangePrices !== false && (
                  <Heading size={'2xl'}>
                    $
                    {(isNaN(totalGains * exchangePrices[route.params.coinName])
                      ? 0.0
                      : totalGains * exchangePrices[route.params.coinName]
                    ).toFixed(2)}{' '}
                    USD
                  </Heading>
                )
              ) : (
                <Loading />
              )}
            </Box>

            {recentPlays.length > 0 && (
              <FlatList
                horizontal={Platform.OS === 'ios' ? true : false}
                w={'95%'}
                h={'90%'}
                pl={200}
                initialNumToRender={3}
                data={recentPlays}
                renderItem={({ x, index }) => {
                  if (index == 0) {
                    return (
                      <Play
                        style={{ marginLeft: 50 }}
                        key={recentPlays[index]['id']}
                        play={recentPlays[index]}
                        viewtoggle={true}
                      />
                    );
                  }
                  return (
                    <Play
                      key={recentPlays[index]['id']}
                      play={recentPlays[index]}
                      viewtoggle={true}
                    />
                  );
                }}
                keyExtractor={(x, index) => recentPlays[index]['id']}
              />
            )}
          </Center>
        )}

        {currentTab === 'news' && (
          <>
            {news.map((name) => (
              <TouchableOpacity
                m={10}
                onPress={() => Linking.openURL(name.news_url)}
                key={name.news_url}
              >
                <HStack>
                  <Image
                    size={'md'}
                    borderRadius="$none"
                    source={{ uri: name.image_url }}
                    alt={'article image'}
                  />
                  <VStack style={styles.news} mr={3}>
                    <Text isTruncated={true}>{name.title}</Text>
                    <Heading size={'md'}>{name.source_name}</Heading>
                  </VStack>
                </HStack>
                <Divider />
              </TouchableOpacity>
            ))}
            <Text mb={30}>News provided by https://cryptonews-api.com</Text>
          </>
        )}
        {currentTab === 'community' && (
          <Box mb={30} ml={20} mr={20}>
            <Heading>All of CryptoPilot.app</Heading>
            <Text>Total Completed Plays {allCommunityPlays}</Text>
            <Text>Total Gains {allCommunityGains}</Text>
            <Heading>Rankings</Heading>
            {rankings
              .filter((r) => r.user !== 'ALL')
              .map((ranking) => (
                <HStack mt={10} key={ranking.user}>
                  <Avatar bgColor="$indigo600"></Avatar>
                  <Box>
                    <Heading>{dashboardAPI.hideEmail(ranking.user)}</Heading>
                    {exchangePrices !== false && (
                      <Text>
                        {(
                          ranking.crypto_winnings *
                          exchangePrices[route.params.coinName]
                        ).toFixed(2)}{' '}
                        USD
                      </Text>
                    )}
                  </Box>
                </HStack>
              ))}
          </Box>
        )}
      </ScrollView>
      <Actionsheet
        isOpen={showMarketOperationSettings}
        onClose={() =>
          setShowMarketOperationSettings(!showMarketOperationSettings)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            DAY TRADING SETTINGS
          </ActionsheetSectionHeaderText>

          <Button
            onPress={() => setMarketOperationSetting('buy')}
            mt={20}
            borderRadius="$full"
            variant={marketOperation === 'buy' ? 'solid' : 'outline'}
            action={'secondary'}
          >
            <ButtonText>
              {'TODAY ONLY BUY {crypto}'.replace(
                '{crypto}',
                route.params.coinName,
              )}
            </ButtonText>
          </Button>

          <Button
            onPress={() => setMarketOperationSetting('sell')}
            mt={10}
            borderRadius="$full"
            variant={marketOperation === 'sell' ? 'solid' : 'outline'}
            action={'secondary'}
          >
            <ButtonText>
              {'TODAY ONLY SELL {crypto}'.replace(
                '{crypto}',
                route.params.coinName,
              )}
            </ButtonText>
          </Button>
        </ActionsheetContent>
      </Actionsheet>
      <Actionsheet
        isOpen={showInvestSettings}
        onClose={() => setShowInvestSettings(!showInvestSettings)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Investment Modes
          </ActionsheetSectionHeaderText>

          <Button
            onPress={() => setCryptoInvestSetting('crypto')}
            mt={20}
            borderRadius="$full"
            variant={
              cryptoInvestSettings[route.params.coinName] === 'crypto'
                ? 'solid'
                : 'outline'
            }
            action={'secondary'}
          >
            <ButtonText>
              {investmentModeTitles['crypto']
                .replace('{crypto}', route.params.coinName)
                .replace(
                  '{StockOrCrypto}',
                  dashboardAPI.getInvestmentEntityType(route.params.coinName),
                )}
            </ButtonText>
          </Button>

          <Button
            onPress={() => setCryptoInvestSetting('usd')}
            mt={10}
            borderRadius="$full"
            variant={
              cryptoInvestSettings[route.params.coinName] === 'usd'
                ? 'solid'
                : 'outline'
            }
            action={'secondary'}
          >
            <ButtonText>
              {investmentModeTitles['usd'].replace(
                '{crypto}',
                route.params.coinName,
              )}
            </ButtonText>
          </Button>

          <Button
            onPress={() => setCryptoInvestSetting('both')}
            mt={10}
            borderRadius="$full"
            variant={
              cryptoInvestSettings[route.params.coinName] === 'both'
                ? 'solid'
                : 'outline'
            }
            action={'secondary'}
          >
            <ButtonText>
              {investmentModeTitles['both'].replace(
                '{crypto}',
                route.params.coinName,
              )}
            </ButtonText>
          </Button>

          <Button
            onPress={() => setCryptoInvestSetting('dont_invest')}
            mt={10}
            borderRadius="$full"
            variant={
              cryptoInvestSettings[route.params.coinName] === 'dont_invest'
                ? 'solid'
                : 'outline'
            }
            action={'secondary'}
          >
            <ButtonText>
              {investmentModeTitles['dont_invest'].replace(
                '{crypto}',
                route.params.coinName,
              )}
            </ButtonText>
          </Button>
        </ActionsheetContent>
      </Actionsheet>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  news: {
    // flex: 1,
    backgroundColor: '#fff',

    // paddingTop: StatusBar.currentHeight,
    marginRight: 2,
    marginTop: 2,

    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
  image: {
    height: 65,
    width: 65,
    alignSelf: 'flex-end',
  },
});

export default CryptoDetails;
